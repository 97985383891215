import React from 'react';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FotoElector = ({ cedula_foto }) => {


    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const [procesando, set_procesando] = React.useState(false);

    const cerrar_notificacion = (event, reason) => { set_notificacion({...notificacion, mensaje: ""}) };

    React.useEffect(() => {

   
    }, []);


    

    return(
        <Box >
            

            
            
            <Grid container spacing={2}>

                <Grid item xs={12} md={4}>
               
                </Grid>
                <Grid item xs={12} md={4} >
                <div style={{  justifyContent: 'center', alignContent: 'center'}}>
                    <img src={`https://img.sistemasmicronetdom.work/${cedula_foto}.jpg`} height={300} width={300}/> 
                </div>
                </Grid>
                <Grid item xs={12} md={4}>
                
                </Grid>
                  
                
            </Grid>

            <Snackbar
                open={notificacion.mensaje != ""}
                autoHideDuration={6000}
                onClose={cerrar_notificacion}
            
            
            >
                <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
                {notificacion.mensaje}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default FotoElector;