import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// import DataContext from '../../context/DataContext';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import API from '../../api/API';


import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(CategoryScale,LinearScale,BarElement,Title);


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});




  export const data2 = {
    labels: [ 'Afiliados Faltantes', 'Afiliados Alcanzados'],
    datasets: [
      {
        label: '# of Votes',
        data: [1998, 200],
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };



const GrafAfiliadosMeta = ({ recargar_elecciones, eleccion }) => {

    // const { data } = React.useContext(DataContext);

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const [procesando, set_procesando] = React.useState(false);

    const cerrar_notificacion = (event, reason) => set_notificacion({...notificacion, mensaje: ""});

    React.useEffect(() => {


    }, []);

    return(
       
        <Box >
            <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
            <AppBar position="static">
                <Toolbar>
                
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Grafica Afiliados
                </Typography>
                
                </Toolbar>
            </AppBar>
            </Box>
        
            <Grid container spacing={2} >

            <Grid item xs={12} md={4} >
            {/* <div style={{   height: 400, width: 400 }}>

            <Pie data={data1} /> 

            </div> */}
            </Grid> 

            <Grid item xs={12} md={4} >
            <div style={{   height: 400, width: 400 }}>

            <Pie data={data2} /> 

            </div>
            </Grid> 

            <Grid item xs={12} md={4} >  
            {/* <div style={{   height: 400, width: 400 }}>

                <Pie data={data} /> 
               
            </div> */}
            </Grid>
            
            </Grid>

            <Snackbar
                open={notificacion.mensaje != ""}
                autoHideDuration={6000}
                onClose={cerrar_notificacion}
            
            
            >
                <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
                {notificacion.mensaje}
                </Alert>
            </Snackbar>
        </Box>
       
    );
}

export default GrafAfiliadosMeta;