import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DashboardIcon from '@mui/icons-material/Dashboard';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { Summarize } from '@mui/icons-material';

import { useNavigate, useLocation } from "react-router-dom";

export const CoordinadorMenu =  () => {

  let location = useLocation();
  let navigate = useNavigate();

  React.useEffect(() => {

    if(location.pathname == "/coordinador"){
      set_indice(0);
    }

    if(location.pathname == "/coordinador/multiplicadores"){
      set_indice(1);
    }

    if(location.pathname == "/coordinador/reporte_afiliados"){
      set_indice(2);
    }

  }, [location]);

  const [indice, set_indice] = React.useState(1);

  const handleListItemClick = (event, index, path) => {
    set_indice(index);
    navigate(path);
  };
  
  return(
    <React.Fragment>

      <ListItemButton selected={indice === 0} onClick={ (event) => handleListItemClick(event, 0, '/coordinador') }>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Resumen" />
      </ListItemButton>

      <ListItemButton selected={indice === 1} onClick={ (event) => handleListItemClick(event, 1, '/coordinador/multiplicadores') }>
        <ListItemIcon>
          <RecordVoiceOverIcon />
        </ListItemIcon>
        <ListItemText primary="Multiplicadores" />
      </ListItemButton>

      <ListItemButton selected={indice === 2} onClick={ (event) => handleListItemClick(event, 2, '/coordinador/reporte_afiliados') }>
        <ListItemIcon>
          <Summarize />
        </ListItemIcon>
        <ListItemText primary="Reporte Afiliados" />
      </ListItemButton>

    </React.Fragment>
  );

};

