import * as React from 'react';
import { DataGrid, GridToolbar, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';
import FormAfiliado from './FormAfiliado';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DataContext from '../../context/DataContext';
import API from '../../api/API';

import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import GestureIcon from '@mui/icons-material/Gesture';

import { useNavigate, useLocation } from "react-router-dom";

import FormRegistrarFirma from './FormRegistrarFirma';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '80vh',
    overflow : 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const getSelectedRowsToExport = ({ apiRef }) => { const selectedRowIds = selectedGridRowsSelector(apiRef); if (selectedRowIds.size > 0) { return Array.from(selectedRowIds.keys()); } return gridFilteredSortedRowIdsSelector(apiRef); };

const ReporteAfiliados = () => {

    const { data } = React.useContext(DataContext);

    let location = useLocation();

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const cerrar_notificacion = (event, reason) => set_notificacion({...notificacion, mensaje: ""});
    

    const [open_formulario_afiliado, set_open_formulario_afiliado] = React.useState(false);
    const [open_agregar_firma, set_open_agregar_firma] = React.useState(false);

    const handle_open_formulario_afiliado = () => set_open_formulario_afiliado(true);
    const handle_close_formulario_afiliado = () => { set_open_formulario_afiliado(false); set_afiliado_elegido(null); }; 

    const handle_open_agregar_firma = () => set_open_agregar_firma(true);
    const handle_close_agregar_firma = () => { set_open_agregar_firma(false); set_afiliado_elegido(null); set_cedula_afiliar(""); }; 
    
    const [afiliado_elegido, set_afiliado_elegido] = React.useState(null);
    const [cedula_afiliar, set_cedula_afiliar] = React.useState("");

    React.useEffect( () => {
       

        if( afiliado_elegido != null && afiliado_elegido.accion == 'modificar_afiliado' ){

            handle_open_formulario_afiliado();

        }

        if( afiliado_elegido != null && afiliado_elegido.accion == 'agregar_firma' ){

            handle_open_agregar_firma();

        }

    }, [afiliado_elegido]);

    const columns = [     

        { 
            field: 'firma', 
            disableExport: true,
            minWidth: 100, 
            headerName: 'Firma',
            renderCell: ( params ) => { 
                
                return( <Avatar sx={{ width: 80, height: 80 }} variant="square" alt="Firma" src={"data:image/png;base64," + (params.row.firma || "")} />  );

            } 
        },

        { field: 'fecha_registro', minWidth: 150, headerName: 'Fecha de Registro' },

        { 
            field: 'foto', 
            disableExport: true,
            minWidth: 100, 
            headerName: 'Foto',
            renderCell: ( params ) => { 
                
                return( <Avatar variant="square" alt="Firma" src={`https://img.sistemasmicronetdom.work/${params.row.cedula}.jpg`} />  );

            } 
        },
        { 
            field: 'verificado', 
            minWidth: 50, 
            headerName: '¿Verificado?',
            valueFormatter: ({ value }) => value,
            renderCell: ( params) => { 
                
                return( (params.row.verificado == "SI" ? <strong style={{ color: "green" }}>Si</strong> : <strong style={{ color: "red" }}>No</strong>) );

            } 
        },
        { field: 'cedula', minWidth: 150, headerName: 'Cédula'  }, 
        { field: 'nombres', minWidth: 150, headerName: 'Nombres' },
        { field: 'apellidos', minWidth: 150, headerName: 'Apellidos' },
        { field: 'provincia_nombre', minWidth: 150, headerName: 'Provincia' }, 
        { field: 'municipio_nombre', minWidth: 150, headerName: 'Municipio' },
        { field: 'direccion', minWidth: 150, headerName: 'Dirección' },
        { field: 'nacionalidad_nombre', minWidth: 150, headerName: 'Nacionalidad' },
        { field: 'lugar_de_nacimiento_nombre', minWidth: 150, headerName: 'Lugar de Nacimiento' },
        { field: 'sexo', minWidth: 150, headerName: 'Sexo' },
        { field: 'telefono', minWidth: 150, headerName: 'Teléfono' },
        
        { field: 'elector_colegio_nombre', minWidth: 150, headerName: 'Colegio' },
        { field: 'elector_colegio_codigo', minWidth: 150, headerName: 'Código Colegio' },
        { field: 'elector_colegio_municipio_nombre', minWidth: 150, headerName: 'Municipio Colegio' },
        { field: 'elector_colegio_recinto_nombre', minWidth: 150, headerName: 'Recinto' },
        { field: 'elector_colegio_recinto_codigo', minWidth: 150, headerName: 'Código Recinto' },
        { field: 'elector_colegio_recinto_direccion', minWidth: 300, headerName: 'Dirección Recinto' },
        { field: 'elector_recinto_circunscripcion_nombre', minWidth: 150, headerName: 'Circunscripción' },
        { field: 'elector_recinto_circunscripcion_codigo', minWidth: 150, headerName: 'Código Circunscripción' },
        { field: 'elector_municipio_provincia_nombre', minWidth: 150, headerName: 'Provincia Circunscripción' },

        { 
            field: 'modificar',
            disableExport: true,
            type: 'actions', 
            headerName: 'Editar',
            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={ (event) => set_afiliado_elegido({...params.row, accion: 'modificar_afiliado'}) } > <ModeEditIcon /> </IconButton>,

        },
        { 
            field: 'firmar',
            disableExport: true,
            type: 'actions', 
            headerName: 'Firmar',
            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={ (event) => set_afiliado_elegido({...params.row, accion: 'agregar_firma'}) } > <GestureIcon /> </IconButton>,

        },
    ];


    const [afiliados, set_afiliados] = React.useState([]);

    React.useEffect( () => {

        buscar_afiliados();

        if(location.state && location.state.cedula_afiliar) {

            set_cedula_afiliar(location.state.cedula_afiliar);
        }

        if(location.state && location.state.open_formulario_afiliado) {
            set_open_formulario_afiliado(true);
        }

    }, []);
    
    const buscar_afiliados = () => {

        let ENDPOINT = "api/multiplicador/reporte_afiliados/multiplicador";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_afiliados(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const [paginationModel, setPaginationModel] = React.useState({ pageSize: 10, page: 0 });

    return(
        <div style={{ height: '80%' }}>
        <div style={{ flexGrow: 1 }}>

            <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <Fab color="primary" variant="extended" onClick={handle_open_formulario_afiliado}>
                <AddIcon />
                Agregar Afiliado
            </Fab>
            </Box>

            <DataGrid
                rows={afiliados}
                columns={columns}
                pageSizeOptions={[10, 20, 30, 100]}
                checkboxSelection
                disableRowSelectionOnClick 
                slots={{ toolbar: GridToolbar }} 
                slotProps={{ toolbar: { printOptions: { hideFooter: false, hideToolbar: true, getRowsToExport: getSelectedRowsToExport }, csvOptions: { fileName: 'Afiliados', delimiter: ';', utf8WithBom: true } } }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pagination
                autoHeight
            />
            
            <Modal
                open={open_formulario_afiliado}
                onClose={handle_close_formulario_afiliado}
            >
                <Box sx={style}>
                
                <FormAfiliado recargar_afiliados={buscar_afiliados} afiliado={afiliado_elegido} cedula_afiliar={cedula_afiliar} />

                </Box>
            </Modal>

            <Modal
                open={open_agregar_firma}
                onClose={handle_close_agregar_firma}
            >
                <Box sx={style}>
                
                <FormRegistrarFirma recargar_afiliados={buscar_afiliados} afiliado={afiliado_elegido} />

                </Box>
            </Modal>

        
            
        
        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </div>
        </div>
    );
}

export default ReporteAfiliados;