import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import GroupsIcon from '@mui/icons-material/Groups';
import { Summarize } from '@mui/icons-material';

import { useNavigate, useLocation } from "react-router-dom";

export const VerificadorMenu =  () => {

  let location = useLocation();
  let navigate = useNavigate();

  React.useEffect(() => {

    if(location.pathname == "/verificador"){
      set_indice(0);
    }

    if(location.pathname == "/verificador/coordinadores"){
      set_indice(1);
    }

    if(location.pathname == "/verificador/multiplicadores"){
      set_indice(3);
    }

    if(location.pathname == "/verificador/reporte_afiliados"){
      set_indice(2);
    }

  }, [location]);

  const [indice, set_indice] = React.useState(1);

  const handleListItemClick = (event, index, path) => {
    set_indice(index);
    navigate(path);
  };
  
  return(
    <React.Fragment>

      {/* <ListItemButton selected={indice === 0} onClick={ (event) => handleListItemClick(event, 0, '/verificador') }>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Resumen" />
      </ListItemButton> */}

      <ListItemButton selected={indice === 1} onClick={ (event) => handleListItemClick(event, 1, '/verificador/coordinadores') }>
        <ListItemIcon>
          <GroupsIcon />
        </ListItemIcon>
        <ListItemText primary="Coordinadores" />
      </ListItemButton>

      <ListItemButton selected={indice === 3} onClick={ (event) => handleListItemClick(event, 3, '/verificador/multiplicadores') }>
        <ListItemIcon>
          <RecordVoiceOverIcon />
        </ListItemIcon>
        <ListItemText primary="Multiplicadores" />
      </ListItemButton>

      <ListItemButton selected={indice === 2} onClick={ (event) => handleListItemClick(event, 2, '/verificador/reporte_afiliados') }>
        <ListItemIcon>
          <Summarize />
        </ListItemIcon>
        <ListItemText primary="Reporte Afiliados" />
      </ListItemButton>

    </React.Fragment>
  );

};

