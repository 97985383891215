import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DataContext from '../../context/DataContext';
import InputMask from 'react-input-mask';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import API from '../../api/API';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import * as axios from 'axios';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FormRegistrador = ({ recargar_registradores, registrador }) => {

    const { data } = React.useContext(DataContext);

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const [procesando, set_procesando] = React.useState(false);
    const [buscando_en_padron, set_buscando_en_padron] = React.useState("");

    const cerrar_notificacion = () => set_notificacion({...notificacion, mensaje: ""});

    const [nombre, set_nombre] = React.useState({ valor: "", error: false, mensaje: " "});
    const [apellido, set_apellido] = React.useState({ valor: "", error: false, mensaje: " "});
    const [cedula, set_cedula] = React.useState({ valor: "", error: false, mensaje: " "});
    const [correo, set_correo] = React.useState({ valor: "", error: false, mensaje: " "});
    const [provincias, set_provincias] = React.useState([]);
    const [municipios, set_municipios] = React.useState([]);
    const [provincia_id, set_provincia_id] = React.useState({ valor: 0, error: false, mensaje: " "});
    const [municipio_id, set_municipio_id] = React.useState({ valor: 0, error: false, mensaje: " "});
    const [direccion, set_direccion] = React.useState({ valor: "", error: false, mensaje: " "});
    const [celular, set_celular] = React.useState({ valor: "", error: false, mensaje: " "});
    const [contrasena, set_contrasena] = React.useState({ valor: "", error: false, mensaje: " "});

    React.useEffect( () => { 

        let cedula_numeros = cedula.valor;
        cedula_numeros = cedula_numeros.replaceAll('_', '').replaceAll('-', '');
     
        if( !registrador && cedula_numeros.length == 11 ){

            buscar_en_padron(cedula_numeros)

        }

    }, [cedula]);


    const buscar_en_padron = (la_cedula) => {

        set_buscando_en_padron("Buscando en Padron...");

        axios.get(`https://padron-2024.sistemasmicronetdom.work/api/buscar_por_cedula/${la_cedula}`)
        .then( (response) => {

            if(response.data){

                set_nombre({ valor: response.data.nombres || "", error: false, mensaje: " "});
                set_apellido({ valor: (response.data.apellido1 || "") + " " + (response.data.apellido2 || "" ), error: false, mensaje: " "} );
                set_provincia_id({ valor: (response.data.provincia_id || ""), error: false, mensaje: " " });
                set_municipio_id({ valor: (response.data.municipio_id || ""), error: false, mensaje: " " });
    
            }else{

                set_nombre({ valor:  "", error: false, mensaje: " "});
                set_apellido({ valor: "", error: false, mensaje: " "});
                set_provincia_id({ valor: "", error: false, mensaje: " "});
                set_municipio_id({ valor: "", error: false, mensaje: " "});
                set_municipios([]);
        
                set_notificacion({ mensaje: "cédula no encontrada en el padron", tipo: "error" });

            }

            set_buscando_en_padron("");

        })
        .catch( (error) => {

            switch (error.response.status) {
                case 400:
                 set_notificacion({ mensaje: error.response.data[0].msg, tipo: "error" });
                break;
                case 401:
                 set_notificacion({ mensaje: error.response.data.msg, tipo: "error" });
                break;
                case 403:
                 set_notificacion({ mensaje: error.response.data.msg, tipo: "error" });
                break;
                case 500:
                 set_notificacion({ mensaje: error.response.data.msg, tipo: "error" });
                break;

                default:
                 set_notificacion({ mensaje: "error al realizar petición", tipo: "error" });

            }
            
            set_buscando_en_padron("");
            
        });
        

    }
    
    React.useEffect(() => {

        buscar_provincias();

        if(registrador){

            set_nombre({ valor:  registrador.nombre, error: false, mensaje: " "});
            set_apellido({ valor: registrador.apellido, error: false, mensaje: " "});
            set_cedula({ valor: registrador.cedula, error: false, mensaje: " "});
            set_correo({ valor: registrador.correo, error: false, mensaje: " "});
            set_provincia_id({ valor: registrador.provincia_id, error: false, mensaje: " "});
            set_municipio_id({ valor: registrador.municipio_id, error: false, mensaje: " "}); 
            set_direccion({ valor: registrador.direccion, error: false, mensaje: " "});
            set_celular({ valor: registrador.celular, error: false, mensaje: " "});
            set_contrasena({ valor: registrador.contrasena, error: false, mensaje: " "});
            
        }else{

            set_nombre({ valor:  "", error: false, mensaje: " "});
            set_apellido({ valor: "", error: false, mensaje: " "});
            set_cedula({ valor: "", error: false, mensaje: " "});
            set_correo({ valor: "", error: false, mensaje: " "});
            set_provincia_id({ valor: 0, error: false, mensaje: " "});
            set_municipio_id({ valor: 0, error: false, mensaje: " "});
            set_municipios([]);
            set_direccion({ valor: "", error: false, mensaje: " "});
            set_celular({ valor: "", error: false, mensaje: " "});
            set_contrasena({ valor: "", error: false, mensaje: " "});
        
        }

    }, []);

    React.useEffect(() => {

        if(provincia_id.valor){
          
            buscar_municipios(provincia_id.valor);
        }

    }, [provincia_id]);

    const buscar_provincias = () => {

        let ENDPOINT = "api/provincias";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_provincias(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_municipios = (la_provincia_id) => {

        let ENDPOINT = "api/municipios/"+la_provincia_id;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_municipios(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }


    const handleSubmit = (event) => {

        event.preventDefault();

        if(nombre.valor == "" ){
            set_nombre({...nombre, error: true, mensaje: "nombre es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(apellido.valor == "" ){
            set_apellido({...apellido, error: true, mensaje: "apellido es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(cedula.valor == "" ){
            set_cedula({...cedula, error: true, mensaje: "cédula es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(correo.valor == "" ){
            set_correo({...correo, error: true, mensaje: "correo es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(provincia_id.valor == 0 ){
            set_provincia_id({...provincia_id, error: true, mensaje: "provincia es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }


        if(municipio_id.valor == 0 ){
            set_municipio_id({...municipio_id, error: true, mensaje: "municipio es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(direccion.valor == "" ){
            set_direccion({...direccion, error: true, mensaje: "dirección es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(celular.valor == "" ){
            set_celular({...celular, error: true, mensaje: "celular es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(contrasena.valor == "" && registrador == null ){
            set_contrasena({...contrasena, error: true, mensaje: "contraseña es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        registrar_registrador();

    }

    const registrar_registrador = () => {

        set_procesando(true);

        let ENDPOINT = registrador ? "api/registradores/" + registrador.id : "api/registradores";
        let METODO = registrador ?  "PUT" : "POST" ;
        let DATOS = { 
            nombre: nombre.valor,
            apellido: apellido.valor,
            cedula: cedula.valor.replace("-", "").replace("-", ""),
            correo: correo.valor,
            provincia_id:  provincia_id.valor,
            municipio_id: municipio_id.valor,
            direccion: direccion.valor,
            celular: celular.valor.replace("-", "").replace("-", ""),
            contrasena: registrador ? ( contrasena.valor || null) : contrasena.valor
        };

        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            if( registrador == null ){

                set_nombre({ valor:  "", error: false, mensaje: " "});
                set_apellido({ valor: "", error: false, mensaje: " "});
                set_cedula({ valor: "", error: false, mensaje: " "});
                set_correo({ valor: "", error: false, mensaje: " "});     
                set_provincia_id({ valor: 0, error: false, mensaje: " "});
                set_municipio_id({ valor: 0, error: false, mensaje: " "});
                set_municipios([]);
                set_direccion({ valor: "", error: false, mensaje: " "});
                set_celular({ valor: "", error: false, mensaje: " "});
                set_contrasena({ valor: "", error: false, mensaje: " "});
              
            }

           
            set_notificacion({ mensaje: "data registrada con exito", tipo: "success" } );        
            set_procesando(false);

            recargar_registradores();
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
            set_procesando(false);

        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);


    }



    return(
        <Box component="form" noValidate onSubmit={handleSubmit}>
             <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
            <AppBar position="static">
                <Toolbar>
                
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Registrador
                </Typography>
                     
                </Toolbar>
            </AppBar>
            </Box>

            <Divider>
            <Typography variant="subtitle2" gutterBottom component="div" mt={1}>
                Datos Personales
            </Typography>
            </Divider>
            

      
            <Grid container spacing={2}>

                    <Grid item xs={12} md={3}>
                    <InputMask
                        mask="999-9999999-9"
                        value={cedula.valor}
                        onChange={ (event) => set_cedula({ valor: event.target.value, error: false, mensaje: " "}) }
                        >
                        { () =>       
                            <TextField
                                error={cedula.error}
                                helperText={ buscando_en_padron || cedula.mensaje }
                                required
                                fullWidth
                                name="cedula"
                                label="Cédula"
                                type="text"
                                id="cedula"
                            
                            /> 
                        }
                    </InputMask>    
                    </Grid>

                    <Grid item xs={12} md={3}>
                    <TextField
                        error={nombre.error}
                        helperText={nombre.mensaje}
                        
                        required
                        fullWidth
                        name="nombre"
                        label="Nombre"
                        type="text"
                        id="nombre"
                        value={nombre.valor}
                        onChange={ (event) => set_nombre({ valor: event.target.value, error: false, mensaje: " "}) }
                        
                        />
                        
                    </Grid>

                    <Grid item xs={12} md={3}>
                    <TextField
                        error={apellido.error}
                        helperText={apellido.mensaje}
                        
                        required
                        fullWidth
                        name="apellido"
                        label="Apellido"
                        type="text"
                        id="apellido"
                        value={apellido.valor}
                        onChange={ (event) => set_apellido({ valor: event.target.value, error: false, mensaje: " "}) }
                        
                        />
                        
                    </Grid>

                    <Grid item xs={12} md={3}>
                    <TextField
                        error={correo.error}
                        helperText={correo.mensaje}
                        
                        required
                        fullWidth
                        name="correo"
                        label="Correo"
                        type="text"
                        id="correo"
                        value={correo.valor}
                        onChange={ (event) => set_correo({ valor: event.target.value, error: false, mensaje: " "}) }
                        
                        />
                        
                    </Grid>
                
            </Grid>

            <Divider>
            <Typography variant="subtitle2" gutterBottom component="div" mt={1}>
                    Dirección
            </Typography>
            </Divider>

            <Grid container spacing={2}>

                    <Grid item xs={12} md={4}>

                    <TextField
                        
                        required
                        fullWidth
                        select
                        label="Provincia"
                        value={provincia_id.valor}
                        onChange={(event) => {

                            set_provincia_id({ valor: event.target.value, error: false, mensaje: " "});
                            set_municipio_id({ valor: 0, error: false, mensaje: " "});
                        
                        }}
                        error={provincia_id.error}
                        helperText={provincia_id.mensaje}
                        >
                        {
                            provincias.map( (provincia) => {
                                return(
                                    <MenuItem key={provincia.id} value={provincia.id}>{provincia.nombre}</MenuItem>
                                );
                            })
                            
                        }
                    </TextField>             
                        
                    </Grid>

                    <Grid item xs={12} md={4}>

                    <TextField
                        
                        required
                        fullWidth
                        select
                        label="Municipio"
                        value={municipio_id.valor}
                        onChange={(event) => set_municipio_id({ valor: event.target.value, error: false, mensaje: " "})}
                        error={municipio_id.error}
                        helperText={municipio_id.mensaje}
                        >
                        {
                            municipios.map( (municipio) => {
                                return(
                                    <MenuItem key={municipio.id} value={municipio.id}>{municipio.nombre}</MenuItem>
                                );
                            })
                            
                        }
                    </TextField>
                    
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                    <TextField
                        error={direccion.error}
                        helperText={direccion.mensaje}
                        
                        required
                        fullWidth
                        name="direccion"
                        label="Dirección"
                        type="text"
                        id="direccion"
                        value={direccion.valor}
                        onChange={ (event) => set_direccion({ valor: event.target.value, error: false, mensaje: " "}) }
                        
                        />
                        
                    </Grid>
                
            </Grid>
            
            <Divider>
            <Typography variant="subtitle2" gutterBottom component="div" mt={1}>
                    Datos de Usuario
            </Typography>
            </Divider>

            <Grid container spacing={2}>

                    <Grid item xs={12} md={3}>

                    <InputMask
                    mask="999-999-9999"
                    value={celular.valor}
                    onChange={ (event) => set_celular({ valor: event.target.value, error: false, mensaje: " "}) }
                    >
                    { () =>       
                        <TextField
                            error={celular.error}
                            helperText={celular.mensaje}
                            required
                            fullWidth
                            label="Celular"
                            type="text"
                        
                        /> 
                    }
                    </InputMask> 
      
                    </Grid>
                    <Grid item xs={12} md={3}>

                    <TextField
                        error={contrasena.error}
                        helperText={contrasena.mensaje}
                        
                        required
                        fullWidth
                        name="contrasena"
                        label="Contraseña"
                        type="password"
                        id="contrasena"
                        value={contrasena.valor}
                        onChange={ (event) => set_contrasena({ valor: event.target.value, error: false, mensaje: " "}) }
                        
                        />
                        
                    </Grid>
                    <Grid item xs={12} md={3}>

                    </Grid>
                    <Grid item xs={12} md={3}>
                        
                    </Grid>
                
            </Grid>


        <Toolbar>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box>
        <Button
              type="submit" 
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
            REGISTRAR
        </Button>
        <Box sx={{ width: '100%', visibility: (procesando ? 'visible' : 'hidden') }}>
            <LinearProgress />
        </Box>
        </Box>
        
        </Toolbar>

        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </Box>
    );
}

export default FormRegistrador;