import  React from 'react';
import { DataGrid, GridToolbar, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import FormEleccion from './FormEleccion';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DataContext from '../../context/DataContext';
import API from '../../api/API';
import CoordinadoresConteoAfiliadosAsistentes from './CoordinadoresConteoAfiliadosAsistentes';
import GrafVotantesEleccion from './GrafVotantesEleccion';
import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/People';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PieChartIcon from '@mui/icons-material/PieChart';


export const data = {
    labels: ['Azua', 'Santo Domingo', 'Barahona', 'Dajabón', 'Duarte', 'El Seibo'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

const getSelectedRowsToExport = ({ apiRef }) => { const selectedRowIds = selectedGridRowsSelector(apiRef); if (selectedRowIds.size > 0) { return Array.from(selectedRowIds.keys()); } return gridFilteredSortedRowIdsSelector(apiRef); };

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '80vh',
    overflow : 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Elecciones = () => {

    const { data } = React.useContext(DataContext);

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const cerrar_notificacion = (event, reason) => { set_notificacion({...notificacion, mensaje: ""}) };

    
    const [open_formulario_eleccion, set_open_formulario_eleccion] = React.useState(false);
    const [open_asistencias, set_open_asistencias] = React.useState(false);
    const [open_grafico_votantes, set_open_grafico_votantes] = React.useState(false);

    const handle_open_formulario_eleccion = () => set_open_formulario_eleccion(true);
    const handle_close_formulario_eleccion = () => {set_open_formulario_eleccion(false); set_eleccion_elegido(null);};

    const handle_open_asistencias = () => set_open_asistencias(true);
    const handle_close_asistencias = () => {set_open_asistencias(false); set_eleccion_elegido(null);};

    const [eleccion_elegido, set_eleccion_elegido] = React.useState(null);

    React.useEffect( () => {


        if( eleccion_elegido != null && eleccion_elegido.accion == 'modificar_eleccion' ){

            handle_open_formulario_eleccion();

        }

        if( eleccion_elegido != null && eleccion_elegido.accion == 'ver_grafica_votantes' ){

            set_open_grafico_votantes(true);

        }

        if( eleccion_elegido != null && eleccion_elegido.accion == 'ver_asistencias' ){

            handle_open_asistencias();

        }

    }, [eleccion_elegido]);

    const columns = [     
        { field: 'nombre', minWidth: 300, headerName: 'Nombre' },
        { field: 'fecha', minWidth: 300, headerName: 'Fecha' },
        { 
            field: 'id',
            type: 'actions', 
            headerName: 'Asistencias Por Coordinador',
            disableExport: true,
            minWidth: 150,
            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={(event) => { set_eleccion_elegido({...params.row, accion: 'ver_asistencias'}); }} > <PeopleIcon /> </IconButton>,

        },
        { 
            field: 'grafico',
            type: 'actions', 
            headerName: 'Grafica Votantes',
            disableExport: true,
            minWidth: 150,

            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={(event) => { set_eleccion_elegido({...params.row, accion: 'ver_grafica_votantes'}); }} > <PieChartIcon /> </IconButton>,

        },
        { 
            field: 'modificar',
            type: 'actions', 
            headerName: 'Editar',
            disableExport: true,
            minWidth: 150,

            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={(event) => { set_eleccion_elegido({...params.row, accion: 'modificar_eleccion'}); }} > <ModeEditIcon /> </IconButton>,

        },
    ];


    const [elecciones, set_elecciones] = React.useState([]);

    React.useEffect( () => {

        buscar_elecciones();

    }, []);
    
    const buscar_elecciones = () => {

        let ENDPOINT = "api/administrador/elecciones";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_elecciones(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const [paginationModel, setPaginationModel] = React.useState({ pageSize: 10, page: 0 });

    return(
        <div style={{  height: '70%' }}>
        <div style={{ flexGrow: 1 }}>

            <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <Fab color="primary" variant="extended" onClick={handle_open_formulario_eleccion}>
                <AddIcon />
                Agregar Elección
            </Fab>
            </Box>

            <DataGrid
                rows={elecciones}
                columns={columns}
                pageSizeOptions={[10, 20, 30, 100]}
                checkboxSelection
                disableRowSelectionOnClick 
                slots={{ toolbar: GridToolbar }} 
                slotProps={{ toolbar: { printOptions: { hideFooter: false, hideToolbar: true, getRowsToExport: getSelectedRowsToExport }, csvOptions: { fileName: 'Coordinadores', delimiter: ';', utf8WithBom: true } } }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pagination
                autoHeight
               />
            
            <Modal
                open={open_formulario_eleccion}
                onClose={handle_close_formulario_eleccion}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                
                <FormEleccion recargar_elecciones={buscar_elecciones} eleccion={eleccion_elegido} />

                </Box>
            </Modal>

            <Modal
                open={open_asistencias}
                onClose={handle_close_asistencias}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                
                <CoordinadoresConteoAfiliadosAsistentes eleccion={eleccion_elegido}/>

                </Box>
            </Modal>

            <Modal
                open={open_grafico_votantes}
                onClose={ () => set_open_grafico_votantes(false) }
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                
                <GrafVotantesEleccion eleccion={eleccion_elegido}/>

                </Box>
            </Modal>

        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </div>
        </div>
    );
}

export default Elecciones;