import * as React from 'react';
import { DataGrid, GridToolbar, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Avatar from '@mui/material/Avatar';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DataContext from '../../context/DataContext';
import API from '../../api/API';

import Modal from '@mui/material/Modal';
import FotoElector from '../FotoElector';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getSelectedRowsToExport = ({ apiRef }) => { const selectedRowIds = selectedGridRowsSelector(apiRef); if (selectedRowIds.size > 0) { return Array.from(selectedRowIds.keys()); } return gridFilteredSortedRowIdsSelector(apiRef); };

const PadronAfiliados = () => {

    const { data } = React.useContext(DataContext);

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const cerrar_notificacion = (event, reason) => set_notificacion({...notificacion, mensaje: ""});
    const [cargando, set_cargando] = React.useState(false);
    
    const [open_foto_elector, set_open_foto_elector] = React.useState(false);
    const [cedula_foto, set_cedula_foto] = React.useState("");

    const [padron_afiliados, set_padron_afiliados] = React.useState([]);
    
    const [provincias, set_provincias] = React.useState([]);
    const [provincia, set_provincia] = React.useState(null);
    const [provincia_id, set_provincia_id] = React.useState(0);
    const [input_provincia, set_input_provincia] = React.useState("");

    const [municipios, set_municipios] = React.useState([]);
    const [municipio, set_municipio] = React.useState(null);
    const [municipio_id, set_municipio_id] = React.useState(0);
    const [input_municipio, set_input_municipio] = React.useState("");

    const [circunscripciones, set_circunscripciones] = React.useState([]);
    const [circunscripcion, set_circunscripcion] = React.useState(null);
    const [circunscripcion_id, set_circunscripcion_id] = React.useState(0);
    const [input_circunscripcion, set_input_circunscripcion] = React.useState("");

    const [recintos, set_recintos] = React.useState([]);
    const [recinto, set_recinto] = React.useState(null);
    const [recinto_id, set_recinto_id] = React.useState(0);
    const [input_recinto, set_input_recinto] = React.useState("");

    const [colegios, set_colegios] = React.useState([]);
    const [colegio, set_colegio] = React.useState(null);
    const [colegio_id, set_colegio_id] = React.useState(0);
    const [input_colegio, set_input_colegio] = React.useState("");


    React.useEffect( () => {

        if(cedula_foto != "" ){
            set_open_foto_elector(true);
        }else{
            set_open_foto_elector(false)
        }

    }, [cedula_foto]);

    const columns = [  
        { 
            field: 'esta_afiliado', 
            minWidth: 50, 
            headerName: '¿Afiliado?',
            valueFormatter: ({ value }) => value ? "SI" : "NO",
            renderCell: ( params) => { 
                
                return( (params.row.esta_afiliado ? <strong style={{ color: "green" }}>Si</strong> : <strong style={{ color: "red" }}>No</strong>) );

            } 
        },
        { 
            field: 'foto', 
            disableExport: true,
            minWidth: 100, 
            headerName: 'Foto',
            renderCell: ( params) => { 
                
                return( <Avatar onClick={ (event) => set_cedula_foto(params.row.cedula) } variant="square" alt="Firma" src={`https://img.sistemasmicronetdom.work/${params.row.cedula}.jpg`} />  );

            } 
        },
        { 
            field: 'verificado', 
            minWidth: 50, 
            headerName: '¿Verificado?',
            valueFormatter: ({ value }) => value,
            renderCell: ( params) => { 
                
                return( (params.row.verificado == "SI" ? <strong style={{ color: "green" }}>Si</strong> : <strong style={{ color: "red" }}>No</strong>) );

            } 
        },   
        { field: 'cedula', minWidth: 150, headerName: 'Cédula' }, 
        { field: 'nombres', minWidth: 150, headerName: 'Nombres' },
        { field: 'apellidos', minWidth: 150, headerName: 'Apellidos' },
        { field: 'provincia_nombre', minWidth: 150, headerName: 'Provincia' }, 
        { field: 'municipio_nombre', minWidth: 150, headerName: 'Municipio' },
        { field: 'direccion', minWidth: 150, headerName: 'Dirección' },
        { field: 'nacionalidad_nombre', minWidth: 150, headerName: 'Nacionalidad' },
        { field: 'lugar_de_nacimiento_nombre', minWidth: 150, headerName: 'Lugar de Nacimiento' },
        { field: 'sexo', minWidth: 150, headerName: 'Sexo' },
        { field: 'telefono', minWidth: 150, headerName: 'Teléfono' },

        { field: 'elector_colegio_nombre', minWidth: 150, headerName: 'Colegio' },
        { field: 'elector_colegio_codigo', minWidth: 150, headerName: 'Código Colegio' },
        { field: 'elector_colegio_municipio_nombre', minWidth: 150, headerName: 'Municipio Colegio' },
        { field: 'elector_colegio_recinto_nombre', minWidth: 150, headerName: 'Recinto' },
        { field: 'elector_colegio_recinto_codigo', minWidth: 150, headerName: 'Código Recinto' },
        { field: 'elector_colegio_recinto_direccion', minWidth: 300, headerName: 'Dirección Recinto' },
        { field: 'elector_recinto_circunscripcion_nombre', minWidth: 150, headerName: 'Circunscripción' },
        { field: 'elector_recinto_circunscripcion_codigo', minWidth: 150, headerName: 'Código Circunscripción' },
        { field: 'elector_municipio_provincia_nombre', minWidth: 150, headerName: 'Provincia Circunscripción' },

        { field: 'fecha_registro', minWidth: 150, headerName: 'Fecha de Registro' },
       
    ];

    const [pagina_actual, set_pagina_actual] = React.useState({ pageSize: 10, page: 0 });
    const [total, set_total] = React.useState(0);

    const cargar_nueva_pagina = (nueva_pagina) => {

        buscar_padron_afiliados(nueva_pagina.pageSize, nueva_pagina.page);

    }
   
    React.useEffect( () => {

        buscar_provincias();
        buscar_padron_afiliados(10, 0);

    }, []);

    React.useEffect( () => {

        set_circunscripciones([])
        set_circunscripcion_id(0);

        set_municipios([]);
        set_municipio_id(0);

        if(provincia_id) {

            set_provincia(provincias.find( p => p.id == provincia_id));
            buscar_municipios(provincia_id);
            buscar_circunscripciones(provincia_id);

        }else{

            set_provincia(null);

        }

    }, [provincia_id]);

    React.useEffect( () => {

        if(municipio_id) {

            set_municipio(municipios.find( m => m.id == municipio_id));

        }else{

            set_municipio(null);
        }

    }, [municipio_id]);

    React.useEffect( () => {

        set_colegios([]);
        set_colegio_id(0);

        if(recinto_id) {

            set_recinto(recintos.find( r => r.id == recinto_id));
            buscar_colegios(recinto_id);

        }else{

            set_recinto(null);
        }

    }, [recinto_id]);

    React.useEffect( () => {

        if(colegio_id) {

            set_colegio(colegios.find( c => c.id == colegio_id));

        }else{

            set_colegio(null);
        }

    }, [colegio_id]);

    React.useEffect( () => {

        set_recintos([]);
        set_recinto_id(0);

        if(circunscripcion_id) {

            set_circunscripcion(circunscripciones.find( c => c.id == circunscripcion_id));
            buscar_recintos(circunscripcion_id);

        }else{

            set_circunscripcion(null);
        }

    }, [circunscripcion_id]);

    const buscar_provincias = () => {

        let ENDPOINT = `api/provincias/lista`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            set_provincias(respuesta);
  
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_municipios = ( la_provincia_id ) => {

        let ENDPOINT = `api/municipios/lista_por_provincia/${la_provincia_id}`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            set_municipios(respuesta);
  
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_circunscripciones = ( la_provincia_id ) => {

        let ENDPOINT = `api/circunscripciones/lista_por_provincia/${la_provincia_id}`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            set_circunscripciones(respuesta);
  
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_recintos = ( la_circunscripcion_id ) => {

        let ENDPOINT = `api/recintos/lista_por_circunscripcion/${la_circunscripcion_id}`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            set_recintos(respuesta);
  
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_colegios = ( el_recinto_id ) => {

        let ENDPOINT = `api/colegios/lista_por_recinto/${el_recinto_id}`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            set_colegios(respuesta);
  
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }
    
    const buscar_padron_afiliados = (cuanto, pagina) => {

        set_cargando(true);

        let ENDPOINT = `api/administrador/padron_afiliados/${cuanto}/${pagina}`;
        let METODO = "POST";
        let DATOS = {
            elector_municipio_provincia_id: provincia_id || null,
            elector_recinto_circunscripcion_id: circunscripcion_id || null,
            elector_colegio_municipio_id: municipio_id || null,
            elector_colegio_recinto_id: recinto_id || null,
            elector_colegio_id: colegio_id || null

        };
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            set_cargando(false);

            set_padron_afiliados(respuesta.registros);
            set_total(respuesta.total);
            set_pagina_actual({ pageSize: cuanto, page: pagina })

  
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_cargando(false);

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const consulta_padron = () => {

        if( provincia_id || circunscripcion_id || municipio_id || recinto_id || colegio_id ){

            buscar_padron_afiliados(10, 0);

        }

    }

    return(
        <div style={{  height: '80%' }}>
        <div style={{ flexGrow: 1 }}>

            <Box sx={{ '& > :not(style)': { marginTop: 3 } }}>
            <Grid container spacing={0}>

                <Grid item xs={12} md={4}>

                <Autocomplete
                    autoHighlight
                    getOptionLabel={(option) => option.nombre}
                    value={provincia}
                    onChange={(event, newValue) => {

                        set_provincia_id(newValue ? newValue.id : 0 );

                    }}
                    inputValue={input_provincia}
                    onInputChange={(event, newInputValue) => {

                        set_input_provincia(newInputValue);

                    }}
                    options={provincias}
                    sx={{ flex: 1, marginRight: 3 }}
                    renderInput={(params) => <TextField {...params} label="Provincia" fullWidth size="small" />}

                />

                </Grid>
                <Grid item xs={12} md={4}>

                <Autocomplete
                    autoHighlight
                    getOptionLabel={(option) => option.nombre}
                    value={municipio}
                    onChange={(event, newValue) => {

                        set_municipio_id(newValue ? newValue.id : 0 );

                    }}
                    inputValue={input_municipio}
                    onInputChange={(event, newInputValue) => {

                        set_input_municipio(newInputValue);

                    }}
                    options={municipios}
                    sx={{ flex: 1, marginRight: 3 }}
                    renderInput={(params) => <TextField {...params} label="Municipio" fullWidth size="small" />}

                />

                </Grid>

                <Grid item xs={12} md={4}>

                <Autocomplete
                    autoHighlight
                    getOptionLabel={(option) => option.nombre}
                    value={circunscripcion}
                    onChange={(event, newValue) => {

                        set_circunscripcion_id(newValue ? newValue.id : 0 );

                    }}
                    inputValue={input_circunscripcion}
                    onInputChange={(event, newInputValue) => {

                        set_input_circunscripcion(newInputValue);

                    }}
                    options={circunscripciones}
                    sx={{ flex: 1, marginRight: 3 }}
                    renderInput={(params) => <TextField {...params} label="Circunscripción" fullWidth size="small" />}

                />

                </Grid>

                
            </Grid>

            <Grid container spacing={0}>

                <Grid item xs={12} md={4}>

                <Autocomplete
                    autoHighlight
                    getOptionLabel={ (option) => option.nombre }
                    value={recinto}
                    onChange={(event, newValue) => {

                        set_recinto_id(newValue ? newValue.id : 0 );

                    }}
                    inputValue={input_recinto}
                    onInputChange={(event, newInputValue) => {

                        set_input_recinto(newInputValue);

                    }}
                    options={recintos}
                    sx={{ flex: 1, marginRight: 3 }}
                    renderInput={(params) => <TextField {...params} label="Recinto" fullWidth size="small" />}

                    />

                </Grid>

                <Grid item xs={12} md={4}>

                <Autocomplete
                    autoHighlight
                    getOptionLabel={ (option) => option.codigo + " - " + option.nombre }
                    value={colegio}
                    onChange={(event, newValue) => {

                        set_colegio_id(newValue ? newValue.id : 0 );

                    }}
                    inputValue={input_colegio}
                    onInputChange={(event, newInputValue) => {

                        set_input_colegio(newInputValue);

                    }}
                    options={colegios}
                    sx={{ flex: 1, marginRight: 3 }}
                    renderInput={(params) => <TextField {...params} label="Colegio" fullWidth size="small" />}

                    />

                </Grid>

            <Grid item xs={12} md={4}>

                <Fab color="primary" variant="extended" onClick={consulta_padron} >
                    Consultar
                </Fab>

            </Grid>
            
            </Grid>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={parseFloat(100/total*100)} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${(100/total*100)}%`}</Typography>
                </Box>
            </Box>

            </Box>

            <DataGrid
                disableColumnFilter 
                rows={padron_afiliados}
                rowCount={total}
                columns={columns}
                pageSizeOptions={[10, 20, 30, 100]}
                checkboxSelection
                disableRowSelectionOnClick 
                slots={{ toolbar: GridToolbar }} 
                slotProps={{ toolbar: { printOptions: { hideFooter: false, hideToolbar: true, getRowsToExport: getSelectedRowsToExport }, csvOptions: { fileName: 'Padron', delimiter: ';', utf8WithBom: true } } }}
                paginationMode="server"
                paginationModel={pagina_actual}
                onPaginationModelChange={cargar_nueva_pagina}
                loading={cargando}
                pagination
                autoHeight
            />
        
            
        <Modal
            open={open_foto_elector}
            onClose={ () => set_cedula_foto("") }
            
            >
            <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    height: 400,
                    overflow : 'auto',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
            
            <FotoElector cedula_foto={cedula_foto} />

            </Box>
        </Modal>

        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </div>
        </div>
    );
}

export default PadronAfiliados;