import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DataContext from '../../context/DataContext';
import InputMask from 'react-input-mask';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import API from '../../api/API';
import CanvasDraw from "react-canvas-draw";
import LinearProgress from '@mui/material/LinearProgress';
import * as axios from 'axios';
import Chip from '@mui/material/Chip';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FormAsistencia = ({ eleccion }) => {

    const { data } = React.useContext(DataContext);

    let canvasRef = React.useRef(null);

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const [procesando, set_procesando] = React.useState(false);
    const [buscando_en_padron, set_buscando_en_padron] = React.useState(false);


    const cerrar_notificacion = () => set_notificacion({...notificacion, mensaje: ""});

    const [cedula, set_cedula] = React.useState({ valor: "", error: false, mensaje: " "});
    const [nombres, set_nombres] = React.useState({ valor: "", error: false, mensaje: " "});
    const [apellidos, set_apellidos] = React.useState({ valor: "", error: false, mensaje: " "});
    
    const [telefono, set_telefono] = React.useState({ valor: "", error: false, mensaje: " "});

    const [provincias, set_provincias] = React.useState([]);
    const [provincia_id, set_provincia_id] = React.useState({ valor: "", error: false, mensaje: " "});

    const [municipios, set_municipios] = React.useState([]); 
    const [municipio_id, set_municipio_id] = React.useState({ valor: "", error: false, mensaje: " "});

    const [direccion, set_direccion] = React.useState({ valor: "", error: false, mensaje: " "});

    const [nacionalidades, set_nacionalidades] = React.useState([]);
    const [nacionalidad_id, set_nacionalidad_id] = React.useState({ valor: "", error: false, mensaje: " "});

    const [lugares_de_naciomiento, set_lugares_de_naciomiento] = React.useState([]);
    const [lugar_de_nacimiento_id, set_lugar_de_nacimiento_id] = React.useState({ valor: "", error: false, mensaje: " "});

    const [sexos, set_sexos] = React.useState([{ id: 'F', nombre: 'Femenino' }, { id: 'M', nombre: 'Masculino'}]);
    const [sexo_id, set_sexo_id] = React.useState({ valor: "", error: false, mensaje: " "});

    
    const [elector_colegio_nombre, set_elector_colegio_nombre] = React.useState("");
    const [elector_colegio_recinto_nombre, set_elector_colegio_recinto_nombre] = React.useState("");


    React.useEffect(() => {

        buscar_provincias();
        buscar_nacionalidades();
        buscar_lugares_de_naciomiento();

        if( !eleccion ){

            set_nombres({ valor:  "", error: false, mensaje: " "});
            set_apellidos({ valor: "", error: false, mensaje: " "});
            set_telefono({ valor: "", error: false, mensaje: " "});
            set_provincia_id({ valor: "", error: false, mensaje: " "});
            set_municipio_id({ valor: "", error: false, mensaje: " "});
            set_municipios([]);
            set_direccion({ valor: "", error: false, mensaje: " "});
            set_nacionalidad_id({ valor: "", error: false, mensaje: " "});
            set_lugar_de_nacimiento_id({ valor: "", error: false, mensaje: " "});
            set_sexo_id({ valor: "", error: false, mensaje: " "});
            set_elector_colegio_nombre(""); 
            set_elector_colegio_recinto_nombre(""); 

        }
        
    }, []);

    React.useEffect(() => {

        set_municipio_id({ valor: "", error: false, mensaje: " "});
        set_municipios([]);

        if(provincia_id.valor){

            buscar_municipios(provincia_id.valor);

        }

    }, [provincia_id]);

    React.useEffect( () => { 

        let cedula_numeros = cedula.valor;
        cedula_numeros = cedula_numeros.replaceAll('_', '').replaceAll('-', '');
     
        if( cedula_numeros.length == 11 ){

            buscar_en_padron(cedula_numeros);

        }

    }, [cedula]);

    const buscar_en_padron = (la_cedula) => {

        set_buscando_en_padron(true);

        axios.get(`https://padron-2024.sistemasmicronetdom.work/api/buscar_por_cedula/${la_cedula}`)
        .then( (response) => {

            if(response.data){

                set_nombres({ valor: response.data.nombres || "", error: false, mensaje: " "});
                set_apellidos({ valor: (response.data.apellido1 || "") + " " + (response.data.apellido2 || "" ), error: false, mensaje: " "} );
                set_provincia_id({ valor: (response.data.provincia_id || ""), error: false, mensaje: " " });
                setTimeout( () => {

                    set_municipio_id({ valor: (response.data.municipio_id || ""), error: false, mensaje: " " });
    
                }, 1000);          
                set_nacionalidad_id({ valor: (response.data.nacionalidad_id || ""), error: false, mensaje: " " });        
                set_lugar_de_nacimiento_id({ valor: (response.data.lugar_de_nacimiento_id || ""), error: false, mensaje: " " });        
                set_sexo_id({ valor: (response.data.sexo || "" ), error: false, mensaje: " "});
                set_elector_colegio_nombre( response.data.elector_colegio_id ? ( response.data.elector_colegio_codigo + " - " + response.data.elector_colegio_nombre) : ""); 
                set_elector_colegio_recinto_nombre( response.data.elector_colegio_recinto_id ? response.data.elector_colegio_recinto_nombre : "");          


            }else{

                set_nombres({ valor:  "", error: false, mensaje: " "});
                set_apellidos({ valor: "", error: false, mensaje: " "});
                set_telefono({ valor: "", error: false, mensaje: " "});
                set_provincia_id({ valor: "", error: false, mensaje: " "});
                set_municipio_id({ valor: "", error: false, mensaje: " "});
                set_municipios([]);
                set_direccion({ valor: "", error: false, mensaje: " "});
                set_nacionalidad_id({ valor: "", error: false, mensaje: " "});
                set_lugar_de_nacimiento_id({ valor: "", error: false, mensaje: " "});
                set_sexo_id({ valor: "", error: false, mensaje: " "});
                set_elector_colegio_nombre(""); 
                set_elector_colegio_recinto_nombre(""); 

                set_notificacion({ mensaje: "cédula no encontrada en el padron", tipo: "error" });

            }

            set_buscando_en_padron(false);

        })
        .catch( (error) => {

            switch (error.response.status) {
                case 400:
                 set_notificacion({ mensaje: error.response.data[0].msg, tipo: "error" });
                break;
                case 401:
                 set_notificacion({ mensaje: error.response.data.msg, tipo: "error" });
                break;
                case 403:
                 set_notificacion({ mensaje: error.response.data.msg, tipo: "error" });
                break;
                case 500:
                 set_notificacion({ mensaje: error.response.data.msg, tipo: "error" });
                break;

                default:
                 set_notificacion({ mensaje: "error al realizar petición", tipo: "error" });

            }	

            set_buscando_en_padron(false);
            
        });
        

    }

    const buscar_provincias = () => {

        let ENDPOINT = "api/provincias";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_provincias(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_nacionalidades = () => {

        let ENDPOINT = "api/nacionalidades";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_nacionalidades(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_lugares_de_naciomiento = () => {

        let ENDPOINT = "api/paises";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_lugares_de_naciomiento(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_municipios = (la_provincia_id) => {

        let ENDPOINT = "api/municipios/"+la_provincia_id;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_municipios(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }


    const handleSubmit = (event) => {

        event.preventDefault();

        let cedula_numeros = cedula.valor;
        cedula_numeros = cedula_numeros.replaceAll('_', '').replaceAll('-', '');

        if(cedula_numeros.length != 11 ){
            set_cedula({...cedula, error: true, mensaje: "cédula es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        let firma =  canvasRef.getDataURL().replace('data:image/png;base64,', '');

        registrar_asistencia(firma);

    }

    const registrar_asistencia = (firma) => {

        set_procesando(true);

        let ENDPOINT = "api/registrador/asistencias_elecciones";
        let METODO = "POST";
        let DATOS = { 
            cedula: cedula.valor.replace("-", "").replace("-", ""),
            eleccion_id: eleccion.id,
            firma
        };

        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
            
            set_cedula({ valor: "", error: false, mensaje: " "});
            set_nombres({ valor:  "", error: false, mensaje: " "});
            set_apellidos({ valor: "", error: false, mensaje: " "});
            set_telefono({ valor: "", error: false, mensaje: " "});
            set_provincia_id({ valor: "", error: false, mensaje: " "});
            set_municipio_id({ valor: "", error: false, mensaje: " "});
            set_municipios([]);
            set_direccion({ valor: "", error: false, mensaje: " "});
            set_nacionalidad_id({ valor: "", error: false, mensaje: " "});
            set_lugar_de_nacimiento_id({ valor: "", error: false, mensaje: " "});
            set_sexo_id({ valor: "", error: false, mensaje: " "});
            set_elector_colegio_nombre("");
            set_elector_colegio_recinto_nombre("");
           
            set_notificacion({ mensaje: "asistencia registrada con éxito.", tipo: "success" } ); 
            set_procesando(false);

    
        };

        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
            set_procesando(false);

        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }



    return(
        <Box component="form" noValidate onSubmit={handleSubmit}>
             <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
            <AppBar position="static">
                <Toolbar>
                
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Registar Asistencia - {eleccion.nombre}
                </Typography>
                     
                </Toolbar>
            </AppBar>
            </Box>

            <Grid container spacing={2}>

            <Grid item xs={12} md={4}>

            </Grid>

            <Grid item xs={12} md={4}>

            <Box sx={{ width: '100%', marginY: 2, visibility: (buscando_en_padron ? 'visible' : 'hidden') }}>
                <LinearProgress />
            </Box> 

            <InputMask
                mask="999-9999999-9"
                value={cedula.valor}
                onChange={ (event) => set_cedula({ valor: event.target.value, error: false, mensaje: " "}) }
                >
                { (props) =>       
                    <TextField
                        {...props}
                        error={cedula.error}
                        helperText={cedula.mensaje}
                        required
                        fullWidth
                        label="Cédula"
                        type="text"

                    /> 
                }
            </InputMask> 
           
            </Grid>

            <Grid item xs={12} md={4}>
                
            </Grid>
            </Grid>

      
            <Grid container spacing={2} justifyContent="center">

            <Grid item xs={12} md={3}>
                <TextField
                    error={nombres.error}
                    helperText={nombres.mensaje}
                    required
                    fullWidth
                    label="Nombres"
                    type="text"
                    value={nombres.valor}
                    onChange={null}
                    
                    />
                
            </Grid>

            <Grid item xs={12} md={3}>
                <TextField
                    error={apellidos.error}
                    helperText={apellidos.mensaje}
                    required
                    fullWidth
                    label="Apellidos"
                    type="text"
                    value={apellidos.valor}
                    onChange={null}
                    
                    />
                
            </Grid>
                
            </Grid>



            <Grid container spacing={2} justifyContent="center">

            <Grid item xs={12} md={3}>

            <TextField
            
                required
                fullWidth
                label="Recinto"
                type="text"
                value={elector_colegio_recinto_nombre}
                onChange={null}
                
                />
               
            </Grid>

            <Grid item xs={12} md={3}>

            <TextField
        
                required
                fullWidth
                label="Colegio"
                type="text"
                value={elector_colegio_nombre}
                onChange={null}
                
                />
               
            </Grid>

            </Grid>




            <Grid container spacing={2}  justifyContent="center">
            <Grid item xs={12} md={6} >
            <Box sx={{  
                        width: '100%', 
                        borderWidth: 1, 
                        borderStyle: 'solid', 
                        borderColor: 'gray', 
                        marginTop: 3,
                        display: 'flex', 
                        justifyContent: 'center',
                        backgroundColor: 'gray'

                    }}>
            <CanvasDraw
                brushColor='#000000'
                lazyRadius={0}
                brushRadius={1}
                canvasWidth={200}
                canvasHeight={200}
                ref={ r => canvasRef = r }
            />
            </Box>  
            </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={3} >
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
            <Chip label="Deshacer" onClick={ () => canvasRef.undo() }/>
            </Box>
            </Grid>

            <Grid item xs={12} md={3}>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
            <Chip label="Limpiar" onClick={ () => canvasRef.clear() }/>
            </Box> 
            </Grid>
            </Grid>

            



        <Toolbar>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box>
        <Button
              type="submit" 
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
            REGISTRAR
        </Button>
        <Box sx={{ width: '100%', visibility: (procesando ? 'visible' : 'hidden') }}>
            <LinearProgress />
        </Box>
        </Box>
        </Toolbar>

        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </Box>
    );
}

export default FormAsistencia;