import * as React from 'react';
import { DataGrid, GridToolbar, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Avatar from '@mui/material/Avatar';


import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DataContext from '../../context/DataContext';
import API from '../../api/API';

import Modal from '@mui/material/Modal';
import FotoElector from '../FotoElector';

import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/People';

import { useNavigate, useLocation } from "react-router-dom";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getSelectedRowsToExport = ({ apiRef }) => { const selectedRowIds = selectedGridRowsSelector(apiRef); if (selectedRowIds.size > 0) { return Array.from(selectedRowIds.keys()); } return gridFilteredSortedRowIdsSelector(apiRef); };

const PadronAfiliados = () => {

    const { data } = React.useContext(DataContext);

    let navigate = useNavigate();

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const cerrar_notificacion = (event, reason) => set_notificacion({...notificacion, mensaje: ""});
    const [cargando, set_cargando] = React.useState(false);
    
    const [open_foto_elector, set_open_foto_elector] = React.useState(false);
    const [cedula_foto, set_cedula_foto] = React.useState("");

    const [padron_afiliados, set_padron_afiliados] = React.useState([]);
    
    React.useEffect( () => {

        if(cedula_foto != "" ){
            set_open_foto_elector(true);
        }else{
            set_open_foto_elector(false)
        }

    }, [cedula_foto]);


    const columns = [  

        { 
            field: 'afiliar',
            disableExport: true,
            type: 'actions', 
            headerName: 'Afiliar',
            renderCell: (params) => !params.row.esta_afiliado ?
            <IconButton size="large" color="primary"  onClick={ (event) => navigate('/multiplicador/reporte_afiliados', { state: { open_formulario_afiliado: true, cedula_afiliar: params.row.cedula }}) } > 
                <PeopleIcon /> 
            </IconButton>
            :
            null
        },
        { 
            field: 'esta_afiliado', 
            minWidth: 50, 
            headerName: '¿Afiliado?',
            valueFormatter: ({ value }) => value ? "SI" : "NO",
            renderCell: ( params) => { 
                
                return( (params.row.esta_afiliado ? <strong style={{ color: "green" }}>Si</strong> : <strong style={{ color: "red" }}>No</strong>) );

            } 
        },
        { 
            field: 'foto', 
            disableExport: true,
            minWidth: 100, 
            headerName: 'Foto',
            renderCell: ( params) => { 
                
                return( <Avatar onClick={ (event) => set_cedula_foto(params.row.cedula) } variant="square" alt="Firma" src={`https://img.sistemasmicronetdom.work/${params.row.cedula}.jpg`} />  );

            } 
        },
        { 
            field: 'verificado', 
            minWidth: 50, 
            headerName: '¿Verificado?',
            valueFormatter: ({ value }) => value,
            renderCell: ( params) => { 
                
                return( (params.row.verificado == "SI" ? <strong style={{ color: "green" }}>Si</strong> : <strong style={{ color: "red" }}>No</strong>) );

            } 
        },   
        { field: 'cedula', minWidth: 150, headerName: 'Cédula' }, 
        { field: 'nombres', minWidth: 150, headerName: 'Nombres' },
        { field: 'apellidos', minWidth: 150, headerName: 'Apellidos' },
        { field: 'provincia_nombre', minWidth: 150, headerName: 'Provincia' }, 
        { field: 'municipio_nombre', minWidth: 150, headerName: 'Municipio' },
        { field: 'direccion', minWidth: 150, headerName: 'Dirección' },
        { field: 'nacionalidad_nombre', minWidth: 150, headerName: 'Nacionalidad' },
        { field: 'lugar_de_nacimiento_nombre', minWidth: 150, headerName: 'Lugar de Nacimiento' },
        { field: 'sexo', minWidth: 150, headerName: 'Sexo' },
        { field: 'telefono', minWidth: 150, headerName: 'Teléfono' },

        { field: 'elector_colegio_nombre', minWidth: 150, headerName: 'Colegio' },
        { field: 'elector_colegio_codigo', minWidth: 150, headerName: 'Código Colegio' },
        { field: 'elector_colegio_municipio_nombre', minWidth: 150, headerName: 'Municipio Colegio' },
        { field: 'elector_colegio_recinto_nombre', minWidth: 150, headerName: 'Recinto' },
        { field: 'elector_colegio_recinto_codigo', minWidth: 150, headerName: 'Código Recinto' },
        { field: 'elector_colegio_recinto_direccion', minWidth: 300, headerName: 'Dirección Recinto' },
        { field: 'elector_recinto_circunscripcion_nombre', minWidth: 150, headerName: 'Circunscripción' },
        { field: 'elector_recinto_circunscripcion_codigo', minWidth: 150, headerName: 'Código Circunscripción' },
        { field: 'elector_municipio_provincia_nombre', minWidth: 150, headerName: 'Provincia Circunscripción' },

        { field: 'fecha_registro', minWidth: 150, headerName: 'Fecha de Registro' },
       
    ];

    const [pagina_actual, set_pagina_actual] = React.useState({ pageSize: 10, page: 0 });
    const [total, set_total] = React.useState(0);

    const cargar_nueva_pagina = (nueva_pagina) => {

        buscar_padron_afiliados(nueva_pagina.pageSize, nueva_pagina.page);

    }
   
    React.useEffect( () => {

        buscar_padron_afiliados(10, 0);

    }, []);
    
    const buscar_padron_afiliados = (cuanto, pagina) => {

        set_cargando(true);

        let ENDPOINT = `api/multiplicador/padron_afiliados/${cuanto}/${pagina}`;
        let METODO = "POST";
        let DATOS = {
           
            elector_colegio_id: data.user.elector_colegio_id

        };
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            set_cargando(false);

            set_padron_afiliados(respuesta.registros);
            set_total(respuesta.total);
            set_pagina_actual({ pageSize: cuanto, page: pagina })

  
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_cargando(false);

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    return(
        <div style={{  height: '80%' }}>
        <div style={{ flexGrow: 1 }}>

            <Box sx={{ '& > :not(style)': { marginTop: 3 } }}>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={parseFloat(200/total*100)} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${(200/total*100)}%`}</Typography>
                </Box>
            </Box>

            </Box>

            <DataGrid
                disableColumnFilter 
                rows={padron_afiliados}
                rowCount={total}
                columns={columns}
                pageSizeOptions={[10, 20, 30, 100]}
                checkboxSelection
                disableRowSelectionOnClick 
                slots={{ toolbar: GridToolbar }} 
                slotProps={{ toolbar: { printOptions: { hideFooter: false, hideToolbar: true, getRowsToExport: getSelectedRowsToExport }, csvOptions: { fileName: 'Padron', delimiter: ';', utf8WithBom: true } } }}
                paginationMode="server"
                paginationModel={pagina_actual}
                onPaginationModelChange={cargar_nueva_pagina}
                loading={cargando}
                pagination
                autoHeight
            />
        
            
        <Modal
            open={open_foto_elector}
            onClose={ () => set_cedula_foto("") }
            
            >
            <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    height: 400,
                    overflow : 'auto',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
            
            <FotoElector cedula_foto={cedula_foto} />

            </Box>
        </Modal>

        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </div>
        </div>
    );
}

export default PadronAfiliados;