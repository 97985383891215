import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DataContext from '../../context/DataContext';
import InputMask from 'react-input-mask';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import API from '../../api/API';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import * as axios from 'axios';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FormMultiplicador = ({ recargar_multiplicadores, multiplicador }) => {

    const { data } = React.useContext(DataContext);

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const [procesando, set_procesando] = React.useState(false);
    const [buscando_en_padron, set_buscando_en_padron] = React.useState("");

    const cerrar_notificacion = () => set_notificacion({...notificacion, mensaje: ""});

    const [nombre, set_nombre] = React.useState({ valor: "", error: false, mensaje: " "});
    const [apellido, set_apellido] = React.useState({ valor: "", error: false, mensaje: " "});
    const [cedula, set_cedula] = React.useState({ valor: "", error: false, mensaje: " "});
    const [correo, set_correo] = React.useState({ valor: "", error: false, mensaje: " "});
    const [provincias, set_provincias] = React.useState([]);
    const [municipios, set_municipios] = React.useState([]);
    const [provincia_id, set_provincia_id] = React.useState({ valor: "", error: false, mensaje: " "});
    const [municipio_id, set_municipio_id] = React.useState({ valor: "", error: false, mensaje: " "});
    const [direccion, set_direccion] = React.useState({ valor: "", error: false, mensaje: " "});
    const [celular, set_celular] = React.useState({ valor: "", error: false, mensaje: " "});
    const [contrasena, set_contrasena] = React.useState({ valor: "", error: false, mensaje: " "});
    const [meta, set_meta] = React.useState({ valor: "", error: false, mensaje: " "});
    const [fecha_limite, set_fecha_limite] = React.useState({ valor: "", error: false, mensaje: " "});

    const [elector_provincias, set_elector_provincias] = React.useState([]);
    const [elector_provincia, set_elector_provincia] = React.useState(null);
    const [elector_provincia_id, set_elector_provincia_id] = React.useState(0);
    const [elector_input_provincia, set_elector_input_provincia] = React.useState("");
    const [elector_error_provincia, set_elector_error_provincia] = React.useState(" ");

    const [elector_municipios, set_elector_municipios] = React.useState([]);
    const [elector_municipio, set_elector_municipio] = React.useState(null);
    const [elector_municipio_id, set_elector_municipio_id] = React.useState(0);
    const [elector_input_municipio, set_elector_input_municipio] = React.useState("");
    const [elector_error_municipio, set_elector_error_municipio] = React.useState(" ");


    const [elector_circunscripciones, set_elector_circunscripciones] = React.useState([]);
    const [elector_circunscripcion, set_elector_circunscripcion] = React.useState(null);
    const [elector_circunscripcion_id, set_elector_circunscripcion_id] = React.useState(0);
    const [elector_input_circunscripcion, set_elector_input_circunscripcion] = React.useState("");
    const [elector_error_circunscripcion, set_elector_error_circunscripcion] = React.useState(" ");

    const [elector_recintos, set_elector_recintos] = React.useState([]);
    const [elector_recinto, set_elector_recinto] = React.useState(null);
    const [elector_recinto_id, set_elector_recinto_id] = React.useState(0);
    const [elector_input_recinto, set_elector_input_recinto] = React.useState("");
    const [elector_error_recinto, set_elector_error_recinto] = React.useState(" ");


    const [elector_colegios, set_elector_colegios] = React.useState([]);
    const [elector_colegio, set_elector_colegio] = React.useState(null);
    const [elector_colegio_id, set_elector_colegio_id] = React.useState(0);
    const [elector_input_colegio, set_elector_input_colegio] = React.useState("");
    const [elector_error_colegio, set_elector_error_colegio] = React.useState(" ");


    React.useEffect( () => { 

        let cedula_numeros = cedula.valor;
        cedula_numeros = cedula_numeros.replaceAll('_', '').replaceAll('-', '');
     
        if( !multiplicador && cedula_numeros.length == 11 ){

            buscar_en_padron(cedula_numeros)

        }

    }, [cedula]);


    const buscar_en_padron = (la_cedula) => {

        set_buscando_en_padron("Buscando en Padron...");

        axios.get(`https://padron-2024.sistemasmicronetdom.work/api/buscar_por_cedula/${la_cedula}`)
        .then( (response) => {

            if(response.data){

                set_nombre({ valor: response.data.nombres || "", error: false, mensaje: " "});
                set_apellido({ valor: ( (response.data.apellido1 + " ") || "") + (response.data.apellido2 || "" ), error: false, mensaje: " "} );
               
                set_provincia_id({ valor: (response.data.provincia_id || ""), error: false, mensaje: " " });
                set_municipio_id({ valor: (response.data.municipio_id || ""), error: false, mensaje: " " });

                set_elector_provincia_id(response.data.elector_municipio_provincia_id);
                set_elector_municipio_id(response.data.elector_colegio_municipio_id);
                set_elector_circunscripcion_id(response.data.elector_recinto_circunscripcion_id);
                set_elector_recinto_id(response.data.elector_colegio_recinto_id);
                set_elector_colegio_id(response.data.elector_colegio_id);
    
              
            }else{

                set_nombre({ valor:  "", error: false, mensaje: " "});
                set_apellido({ valor: "", error: false, mensaje: " "});

                set_provincia_id({ valor: "", error: false, mensaje: " "});
                set_municipio_id({ valor: "", error: false, mensaje: " "});

                set_municipios([]);
                set_elector_provincia_id(0);
                set_elector_municipio_id(0);
                set_elector_circunscripcion_id(0);
                set_elector_recinto_id(0);
                set_elector_colegio_id(0);
        
                set_notificacion({ mensaje: "cédula no encontrada en el padron", tipo: "error" });

            }

            set_buscando_en_padron("");

        })
        .catch( (error) => {

            switch (error.response.status) {
                case 400:
                 set_notificacion({ mensaje: error.response.data[0].msg, tipo: "error" });
                break;
                case 401:
                 set_notificacion({ mensaje: error.response.data.msg, tipo: "error" });
                break;
                case 403:
                 set_notificacion({ mensaje: error.response.data.msg, tipo: "error" });
                break;
                case 500:
                 set_notificacion({ mensaje: error.response.data.msg, tipo: "error" });
                break;

                default:
                 set_notificacion({ mensaje: "error al realizar petición", tipo: "error" });

            }
            
            set_buscando_en_padron("");
            
        });
        

    }

    React.useEffect(() => {

        buscar_provincias();
        buscar_elector_provincias();

        if(multiplicador){

            set_nombre({ valor:  multiplicador.nombre, error: false, mensaje: " "});
            set_apellido({ valor: multiplicador.apellido, error: false, mensaje: " "});
            set_cedula({ valor: multiplicador.cedula, error: false, mensaje: " "});
            set_correo({ valor: multiplicador.correo, error: false, mensaje: " "});
            set_provincia_id({ valor: multiplicador.provincia_id, error: false, mensaje: " "});
            set_municipio_id({ valor: multiplicador.municipio_id, error: false, mensaje: " "}); 
            set_direccion({ valor: multiplicador.direccion, error: false, mensaje: " "});
            set_celular({ valor: multiplicador.celular, error: false, mensaje: " "});
            set_contrasena({ valor: multiplicador.contrasena, error: false, mensaje: " "});
            set_meta({ valor: multiplicador.meta, error: false, mensaje: " "});
            set_fecha_limite({ valor: multiplicador.fecha_limite, error: false, mensaje: " "});
            set_elector_provincia_id(multiplicador.elector_municipio_provincia_id);
            set_elector_municipio_id(multiplicador.elector_colegio_municipio_id);
            set_elector_circunscripcion_id(multiplicador.elector_recinto_circunscripcion_id);
            set_elector_recinto_id(multiplicador.elector_colegio_recinto_id);
            set_elector_colegio_id(multiplicador.elector_colegio_id);

            
        }else{

            set_nombre({ valor:  "", error: false, mensaje: " "});
            set_apellido({ valor: "", error: false, mensaje: " "});
            set_cedula({ valor: "", error: false, mensaje: " "});
            set_correo({ valor: "", error: false, mensaje: " "});
            set_provincia_id({ valor: "", error: false, mensaje: " "});
            set_municipio_id({ valor: "", error: false, mensaje: " "});
            set_municipios([]);
            set_direccion({ valor: "", error: false, mensaje: " "});
            set_celular({ valor: "", error: false, mensaje: " "});
            set_contrasena({ valor: "", error: false, mensaje: " "});
            set_meta({ valor: "", error: false, mensaje: " "});
            set_fecha_limite({ valor: "", error: false, mensaje: " "});
            set_elector_provincia_id(0);
            set_elector_municipio_id(0);
            set_elector_circunscripcion_id(0);
            set_elector_recinto_id(0);
            set_elector_colegio_id(0);

        }

    }, []);

    React.useEffect( () => {

        set_elector_circunscripciones([]);
        set_elector_municipios([]);
     
        if(elector_provincia_id && elector_provincias.length > 0 ) {

            set_elector_provincia(elector_provincias.find( p => p.id == elector_provincia_id));

            buscar_elector_municipios(elector_provincia_id);
            buscar_elector_circunscripciones(elector_provincia_id);

        }else{

            set_elector_provincia(null);
            

        }

    }, [elector_provincia_id, elector_provincias]);

    React.useEffect( () => {

        if( elector_municipio_id && elector_municipios.length > 0 ) {

            set_elector_municipio(elector_municipios.find( m => m.id == elector_municipio_id));

        }else{

            set_elector_municipio(null);
        }

    }, [elector_municipio_id, elector_municipios]);

    React.useEffect( () => {

        set_elector_recintos([]);

        if( elector_circunscripcion_id && elector_circunscripciones.length > 0 ) {

            set_elector_circunscripcion(elector_circunscripciones.find( c => c.id == elector_circunscripcion_id));
            buscar_elector_recintos(elector_circunscripcion_id);

        }else{

            set_elector_circunscripcion(null);
        }

    }, [elector_circunscripcion_id, elector_circunscripciones]);

    React.useEffect( () => {

        set_elector_colegios([]);

        if( elector_recinto_id && elector_recintos.length > 0 ) {

            set_elector_recinto(elector_recintos.find( r => r.id == elector_recinto_id));
            buscar_elector_colegios(elector_recinto_id);

        }else{

            set_elector_recinto(null);
        }

    }, [elector_recinto_id, elector_recintos]);

    React.useEffect( () => {

        if( elector_colegio_id && elector_colegios.length > 0 ) {

            set_elector_colegio(elector_colegios.find( c => c.id == elector_colegio_id));

        }else{

            set_elector_colegio(null);
        }

    }, [elector_colegio_id, elector_colegios]);

    React.useEffect(() => {

        set_municipios([]);

        if(provincia_id.valor){

            buscar_municipios(provincia_id.valor);

        }

    }, [provincia_id]);

    const buscar_provincias = () => {

        let ENDPOINT = "api/provincias";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_provincias(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_municipios = (la_provincia_id) => {

        let ENDPOINT = "api/municipios/"+la_provincia_id;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_municipios(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_elector_provincias = () => {

        let ENDPOINT = `api/provincias/lista`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            set_elector_provincias(respuesta);
  
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_elector_municipios = ( la_provincia_id ) => {

        let ENDPOINT = `api/municipios/lista_por_provincia/${la_provincia_id}`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            set_elector_municipios(respuesta);
  
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_elector_circunscripciones = ( la_provincia_id ) => {

        let ENDPOINT = `api/circunscripciones/lista_por_provincia/${la_provincia_id}`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            set_elector_circunscripciones(respuesta);
  
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_elector_recintos = ( la_circunscripcion_id ) => {

        let ENDPOINT = `api/recintos/lista_por_circunscripcion/${la_circunscripcion_id}`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            set_elector_recintos(respuesta);
  
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_elector_colegios = ( el_recinto_id ) => {

        let ENDPOINT = `api/colegios/lista_por_recinto/${el_recinto_id}`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            set_elector_colegios(respuesta);
  
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const handleSubmit = (event) => {

        event.preventDefault();

        if(nombre.valor == "" ){
            set_nombre({...nombre, error: true, mensaje: "nombre es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(apellido.valor == "" ){
            set_apellido({...apellido, error: true, mensaje: "apellido es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(cedula.valor == "" ){
            set_cedula({...cedula, error: true, mensaje: "cédula es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(correo.valor == "" ){
            set_correo({...correo, error: true, mensaje: "correo es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(provincia_id.valor == "" ){
            set_provincia_id({...provincia_id, error: true, mensaje: "provincia es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }


        if(municipio_id.valor == "" ){
            set_municipio_id({...municipio_id, error: true, mensaje: "municipio es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(direccion.valor == "" ){
            set_direccion({...direccion, error: true, mensaje: "dirección es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(celular.valor == "" ){
            set_celular({...celular, error: true, mensaje: "celular es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(contrasena.valor == "" && multiplicador == null ){
            set_contrasena({...contrasena, error: true, mensaje: "contraseña es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(meta.valor == "" ){
            set_meta({...meta, error: true, mensaje: "meta es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(fecha_limite.valor == "" ){
            set_fecha_limite({...fecha_limite, error: true, mensaje: "fecha límite es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(elector_colegio_id == 0 ){
            set_elector_error_colegio("colegio es requerido");
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        registrar_multiplicador();

    }

    const registrar_multiplicador = () => {

        set_procesando(true);

        let ENDPOINT = multiplicador ? "api/coordinador/multiplicadores/" + multiplicador.id : "api/coordinador/multiplicadores";
        let METODO = multiplicador ?  "PUT" : "POST" ;
        let DATOS = { 
            nombre: nombre.valor,
            apellido: apellido.valor,
            cedula: cedula.valor.replace("-", "").replace("-", ""),
            correo: correo.valor,
            provincia_id:  provincia_id.valor,
            municipio_id: municipio_id.valor,
            direccion: direccion.valor,
            celular: celular.valor.replace("-", "").replace("-", ""),
            contrasena: multiplicador ? ( contrasena.valor || null) : contrasena.valor,
            meta: meta.valor,
            fecha_limite: fecha_limite.valor,
            elector_colegio_id: elector_colegio_id
        };

        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            if( multiplicador == null ){

                set_nombre({ valor:  "", error: false, mensaje: " "});
                set_apellido({ valor: "", error: false, mensaje: " "});
                set_cedula({ valor: "", error: false, mensaje: " "});
                set_correo({ valor: "", error: false, mensaje: " "});
                set_provincia_id({ valor: "", error: false, mensaje: " "});
                set_municipio_id({ valor: "", error: false, mensaje: " "});
                set_municipios([]);
                set_direccion({ valor: "", error: false, mensaje: " "});
                set_celular({ valor: "", error: false, mensaje: " "});
                set_contrasena({ valor: "", error: false, mensaje: " "});
                set_meta({ valor: "", error: false, mensaje: " "});
                set_fecha_limite({ valor: "", error: false, mensaje: " "});
                set_elector_provincia_id(0);
                set_elector_municipio_id(0);
                set_elector_circunscripcion_id(0);
                set_elector_recinto_id(0);
                set_elector_colegio_id(0);
              
            }

           
            set_notificacion({ mensaje: "data registrada con exito", tipo: "success" } ); 
            set_procesando(false);

            recargar_multiplicadores();
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
            set_procesando(false);

        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);


    }



    return(
        <Box component="form" noValidate onSubmit={handleSubmit}>
             <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
            <AppBar position="static">
                <Toolbar>
                
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Multiplicador
                </Typography>
                     
                </Toolbar>
            </AppBar>
            </Box>

            <Divider>
            <Typography variant="subtitle2" gutterBottom component="div" mt={1}>
                Datos Personales
            </Typography>
            </Divider>
            

      
            <Grid container spacing={2}>

                    <Grid item xs={12} md={3}>
                    <InputMask
                        mask="999-9999999-9"
                        value={cedula.valor}
                        onChange={ (event) => set_cedula({ valor: event.target.value, error: false, mensaje: " "}) }
                        >
                        { () =>       
                            <TextField
                                error={cedula.error}
                                helperText={  buscando_en_padron || cedula.mensaje}
                                required
                                fullWidth
                                label="Cédula"
                                type="text" 

                            /> 
                        }
                    </InputMask>    
                    </Grid>

                    <Grid item xs={12} md={3}>

                    <TextField
                        error={nombre.error}
                        helperText={nombre.mensaje}
                        
                        required
                        fullWidth
                        name="nombre"
                        label="Nombre"
                        type="text"
                        id="nombre"
                        value={nombre.valor}
                        onChange={ (event) => set_nombre({ valor: event.target.value, error: false, mensaje: " "}) }
                        
                        />
                        
                    </Grid>

                    <Grid item xs={12} md={3}>

                    <TextField
                        error={apellido.error}
                        helperText={apellido.mensaje}
                        
                        required
                        fullWidth
                        name="apellido"
                        label="Apellido"
                        type="text"
                        id="apellido"
                        value={apellido.valor}
                        onChange={ (event) => set_apellido({ valor: event.target.value, error: false, mensaje: " "}) }
                        
                        />
                        
                    </Grid>
                   
                    <Grid item xs={12} md={3}>

                    <TextField
                        error={correo.error}
                        helperText={correo.mensaje}
                        
                        required
                        fullWidth
                        name="correo"
                        label="Correo"
                        type="text"
                        id="correo"
                        value={correo.valor}
                        onChange={ (event) => set_correo({ valor: event.target.value, error: false, mensaje: " "}) }
                        
                        />
                        
                    </Grid>
                
            </Grid>

            <Divider>
            <Typography variant="subtitle2" gutterBottom component="div" mt={1}>
                    Dirección
            </Typography>
            </Divider>

            <Grid container spacing={2}>

                    <Grid item xs={12} md={4}>

                    <TextField
                        
                        required
                        fullWidth
                        select
                        label="Provincia"
                        value={provincia_id.valor}
                        onChange={(event) => {

                            set_provincia_id({ valor: event.target.value, error: false, mensaje: " "})
                            set_municipio_id({ valor: 0, error: false, mensaje: " "});
                        
                        } }
                        error={provincia_id.error}
                        helperText={provincia_id.mensaje}
                        >
                        {
                            provincias.map( (provincia) => {
                                return(
                                    <MenuItem key={provincia.id} value={provincia.id}>{provincia.nombre}</MenuItem>
                                );
                            })
                            
                        }
                    </TextField>             
                        
                    </Grid>

                    <Grid item xs={12} md={4}>

                    <TextField
                        
                        required
                        fullWidth
                        select
                        label="Municipio"
                        value={municipio_id.valor}
                        onChange={(event) => set_municipio_id({ valor: event.target.value, error: false, mensaje: " "})}
                        error={municipio_id.error}
                        helperText={municipio_id.mensaje}
                        >
                        {
                            municipios.map( (municipio) => {
                                return(
                                    <MenuItem key={municipio.id} value={municipio.id}>{municipio.nombre}</MenuItem>
                                );
                            })
                            
                        }
                    </TextField>
                    
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                    <TextField
                        error={direccion.error}
                        helperText={direccion.mensaje}
                        
                        required
                        fullWidth
                        label="Dirección"
                        type="text"
                        value={direccion.valor}
                        onChange={ (event) => set_direccion({ valor: event.target.value, error: false, mensaje: " "}) }
                        
                        />
                        
                    </Grid>
                
            </Grid>
            
            

            <Divider>
            <Typography variant="subtitle2" gutterBottom component="div" mt={1}>
                    Datos Electorales
            </Typography>
            </Divider>

            <Grid container spacing={2}>

                <Grid item xs={12} md={4}>

                <Autocomplete
                    autoHighlight
                    getOptionLabel={(option) => option.nombre}
                    value={elector_provincia}
                    onChange={(event, newValue) => {

                        set_elector_provincia_id(newValue ? newValue.id : 0 );
                        set_elector_circunscripcion_id(0);
                        set_elector_municipio_id(0);
                        set_elector_recinto_id(0);
                        set_elector_colegio_id(0);

                    }}
                    inputValue={elector_input_provincia}
                    onInputChange={(event, newInputValue) => {

                        set_elector_input_provincia(newInputValue);

                    }}
                    options={elector_provincias}
                    sx={{ flex: 1, marginRight: 3 }}
                    renderInput={(params) => <TextField error={elector_error_provincia != " "} helperText={elector_error_provincia} {...params} label="Provincia" fullWidth required />}

                />

                </Grid>

                <Grid item xs={12} md={4}>

                <Autocomplete
                    autoHighlight
                    getOptionLabel={(option) => option.nombre}
                    value={elector_municipio}
                    onChange={(event, newValue) => {

                        set_elector_municipio_id(newValue ? newValue.id : 0 );

                    }}
                    inputValue={elector_input_municipio}
                    onInputChange={(event, newInputValue) => {

                        set_elector_input_municipio(newInputValue);

                    }}
                    options={elector_municipios}
                    sx={{ flex: 1, marginRight: 3 }}
                    renderInput={(params) => <TextField error={elector_error_municipio != " "} helperText={elector_error_municipio} {...params} label="Municipio" fullWidth required />}

                />

                </Grid>

                <Grid item xs={12} md={4}>

                <Autocomplete
                    autoHighlight
                    getOptionLabel={(option) => option.nombre}
                    value={elector_circunscripcion}
                    onChange={(event, newValue) => {

                        set_elector_circunscripcion_id(newValue ? newValue.id : 0 );
                        set_elector_recinto_id(0);
                        set_elector_colegio_id(0);
                        
                    }}
                    inputValue={elector_input_circunscripcion}
                    onInputChange={(event, newInputValue) => {

                        set_elector_input_circunscripcion(newInputValue);

                    }}
                    options={elector_circunscripciones}
                    sx={{ flex: 1, marginRight: 3 }}
                    renderInput={(params) => <TextField error={elector_error_circunscripcion != " "} helperText={elector_error_circunscripcion} {...params} label="Circunscripción" fullWidth required />}

                />

                </Grid>

            </Grid>

            <Grid container spacing={2}>

                <Grid item xs={12} md={4}>

                <Autocomplete
                    autoHighlight
                    getOptionLabel={ (option) => option.nombre }
                    value={elector_recinto}
                    onChange={(event, newValue) => {

                        set_elector_recinto_id(newValue ? newValue.id : 0 );
                        set_elector_colegio_id(0);
                        
                    }}
                    inputValue={elector_input_recinto}
                    onInputChange={(event, newInputValue) => {

                        set_elector_input_recinto(newInputValue);

                    }}
                    options={elector_recintos}
                    sx={{ flex: 1, marginRight: 3 }}
                    renderInput={(params) => <TextField error={elector_error_recinto != " "} helperText={elector_error_recinto} {...params} label="Recinto" fullWidth required />}

                    />

                </Grid>

                <Grid item xs={12} md={4}>

                <Autocomplete
                    autoHighlight
                    getOptionLabel={ (option) => option.codigo + " - " + option.nombre }
                    value={elector_colegio}
                    onChange={(event, newValue) => {

                        set_elector_colegio_id(newValue ? newValue.id : 0 );

                    }}
                    inputValue={elector_input_colegio}
                    onInputChange={(event, newInputValue) => {

                        set_elector_input_colegio(newInputValue);

                    }}
                    options={elector_colegios}
                    sx={{ flex: 1, marginRight: 3 }}
                    renderInput={(params) => <TextField error={elector_error_colegio != " "} helperText={elector_error_colegio} {...params} label="Colegio" fullWidth required />}

                    />

                </Grid>

                <Grid item xs={12} md={4}>

                </Grid>
            
            </Grid>

            <Divider>
            <Typography variant="subtitle2" gutterBottom component="div" mt={1}>
                    Datos de Usuario
            </Typography>
            </Divider>

            <Grid container spacing={2}>

                <Grid item xs={12} md={3}>

                <InputMask
                    mask="999-999-9999"
                    value={celular.valor}
                    onChange={ (event) => set_celular({ valor: event.target.value, error: false, mensaje: " "}) }
                    >
                    { () =>       
                        <TextField
                            error={celular.error}
                            helperText={celular.mensaje}
                            required
                            fullWidth
                            label="Celular"
                            type="text"
                        
                            /> 
                    }
                </InputMask> 
    
                </Grid>
                <Grid item xs={12} md={3}>

                <TextField
                    error={contrasena.error}
                    helperText={contrasena.mensaje}
                    required
                    fullWidth
                    name="contrasena"
                    label="Contraseña"
                    type="password"
                    id="contrasena"
                    value={contrasena.valor}
                    onChange={ (event) => set_contrasena({ valor: event.target.value, error: false, mensaje: " "}) }
                    
                    />
                    
                </Grid>
                <Grid item xs={12} md={3}>

                    <TextField
                        error={meta.error}
                        helperText={meta.mensaje} 
                        required
                        fullWidth
                        label="Meta"
                        type="number"
                        value={meta.valor}
                        onChange={ (event) => set_meta({ valor: event.target.value, error: false, mensaje: " "}) }
                        
                    />

                </Grid>

                <Grid item xs={12} md={3}>

                    <TextField
                        error={fecha_limite.error}
                        helperText={fecha_limite.mensaje} 
                        required
                        fullWidth
                        label="Fecha Límite"
                        type="date"
                        value={fecha_limite.valor}
                        onChange={ (event) => set_fecha_limite({ valor: event.target.value, error: false, mensaje: " "}) }
                        
                    />
                    
                </Grid>
                
            </Grid>


        <Toolbar>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box>
        <Button
              type="submit" 
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
            REGISTRAR
        </Button>
        <Box sx={{ width: '100%', visibility: (procesando ? 'visible' : 'hidden') }}>
            <LinearProgress />
        </Box>
        </Box>
        
        </Toolbar>

        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </Box>
    );
}

export default FormMultiplicador;