import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import { Summarize } from '@mui/icons-material';
import HailIcon from '@mui/icons-material/Hail';
import FactCheckIcon from '@mui/icons-material/FactCheck';

import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import CategoryIcon from '@mui/icons-material/Category';
import GroupsIcon from '@mui/icons-material/Groups';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { useNavigate, useLocation } from "react-router-dom";


export const AdministradorMenu =  () => {

  let location = useLocation();
  let navigate = useNavigate();

  React.useEffect(() => {

    if(location.pathname == "/administrador"){
      set_indice(0);
    }

    if(location.pathname == "/administrador/coordinadores"){
      set_indice(1);
    }

    if(location.pathname == "/administrador/padron_afiliados"){
      set_indice(2);
    }

    if(location.pathname == "/administrador/resumen_por_municipios"){
      set_indice(3);
    }

    if(location.pathname == "/administrador/resumen_por_circunscripcion"){
      set_indice(4);
    }

    if(location.pathname == "/administrador/resumen_por_recintos"){
      set_indice(5);
    }

    if(location.pathname == "/administrador/reporte_afiliados"){
      set_indice(6);
    }

    if(location.pathname == "/administrador/elecciones"){
      set_indice(7);
    }

    if(location.pathname == "/administrador/reporte_asistencias"){
      set_indice(8);
    }

    if(location.pathname == "/administrador/registradores"){
      set_indice(9);
    }

    if(location.pathname == "/administrador/verificadores"){
      set_indice(10);
    }

    if(location.pathname == "/administrador/resumen_por_colegios"){
      set_indice(11);
    }

    if(location.pathname == "/administrador/movimientos"){
      set_indice(12);
    }

    if(location.pathname == "/administrador/multiplicadores"){
      set_indice(13);
    }

    if(location.pathname == "/administrador/resumen_eleccion"){
      set_indice(14);
    }

  }, [location]);

  const [indice, set_indice] = React.useState(1);

  const handleListItemClick = (event, index, path) => {

    set_indice(index);
    navigate(path);
    
  };
  
  return(
    <React.Fragment>

      <ListItemButton selected={indice === 0} onClick={ (event) => handleListItemClick(event, 0, '/administrador') }>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Resumen" />

      </ListItemButton>

        <Accordion>

        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
        <ListItemText primary="Equipo de Trabajo" /> 
        </AccordionSummary>

          <ListItemButton selected={indice === 12} onClick={ (event) => handleListItemClick(event, 12, '/administrador/movimientos') }>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary="Movimientos" />
          </ListItemButton>
      
          <ListItemButton selected={indice === 1} onClick={ (event) => handleListItemClick(event, 1, '/administrador/coordinadores') }>
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText primary="Coordinadores" />
          </ListItemButton>

          <ListItemButton selected={indice === 13} onClick={ (event) => handleListItemClick(event, 13, '/administrador/multiplicadores') }>
            <ListItemIcon>
              <RecordVoiceOverIcon />
            </ListItemIcon>
            <ListItemText primary="Multiplicadores" />
          </ListItemButton>
          
          <ListItemButton selected={indice === 10} onClick={ (event) => handleListItemClick(event, 10, '/administrador/verificadores') }>
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Verificadores" />
          </ListItemButton>

          <ListItemButton selected={indice === 9} onClick={ (event) => handleListItemClick(event, 9, '/administrador/registradores') }>
            <ListItemIcon>
              <PersonPinIcon />
            </ListItemIcon>
            <ListItemText primary="Registradores" />
          </ListItemButton>

      </Accordion>

      <ListItemButton selected={indice === 2} onClick={ (event) => handleListItemClick(event, 2, '/administrador/padron_afiliados') }>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Padron Electoral" />
      </ListItemButton>

      <Accordion>

      <AccordionSummary expandIcon={<ExpandMoreIcon />} >
      <ListItemText primary="Resumen Electoral" /> 
      </AccordionSummary>

      <ListItemButton selected={indice === 11} onClick={ (event) => handleListItemClick(event, 11, '/administrador/resumen_por_colegios') }>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Por Colegios" />
      </ListItemButton>

      <ListItemButton selected={indice === 5} onClick={ (event) => handleListItemClick(event, 5, '/administrador/resumen_por_recintos') }>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Por Recintos" />
      </ListItemButton>

      <ListItemButton selected={indice === 3} onClick={ (event) => handleListItemClick(event, 3, '/administrador/resumen_por_municipios') }>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Por Municipios" />
      </ListItemButton>

      <ListItemButton selected={indice === 4} onClick={ (event) => handleListItemClick(event, 4, '/administrador/resumen_por_circunscripcion') }>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Por Circunscripciones" />
      </ListItemButton>

      </Accordion>
   
      <ListItemButton selected={indice === 6} onClick={ (event) => handleListItemClick(event, 6, '/administrador/reporte_afiliados') }>
        <ListItemIcon>
          <Summarize />
        </ListItemIcon>
        <ListItemText primary="Reporte Afiliados" />
      </ListItemButton>

      <Accordion>

      <AccordionSummary expandIcon={<ExpandMoreIcon />} >
      <ListItemText primary="Gestión de Elecciones" /> 
      </AccordionSummary>

      <ListItemButton selected={indice === 14} onClick={ (event) => handleListItemClick(event, 14, '/administrador/resumen_eleccion') }>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Resumen de Elección" />

      </ListItemButton>

      <ListItemButton selected={indice === 7} onClick={ (event) => handleListItemClick(event, 7, '/administrador/elecciones') }>
        <ListItemIcon>
          <HailIcon />
        </ListItemIcon>
        <ListItemText primary="Elecciones" />
      </ListItemButton>

      <ListItemButton selected={indice === 8} onClick={ (event) => handleListItemClick(event, 8, '/administrador/reporte_asistencias') }>
        <ListItemIcon>
          <FactCheckIcon />
        </ListItemIcon>
        <ListItemText primary="Reporte Asistencias" />
      </ListItemButton>

      </Accordion>

    </React.Fragment>
  );

};

