import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DataContext from '../../context/DataContext';
import InputMask from 'react-input-mask';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import API from '../../api/API';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import LinearProgress from '@mui/material/LinearProgress';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FormEleccion = ({ recargar_elecciones, eleccion }) => {

    const { data } = React.useContext(DataContext);

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const [procesando, set_procesando] = React.useState(false);

    const cerrar_notificacion = (event, reason) => { set_notificacion({...notificacion, mensaje: ""}) };

    const [nombre, set_nombre] = React.useState({ valor: "", error: false, mensaje: " "});
    const [fecha, set_fecha] = React.useState({valor: moment().format('YYYY-MM-DD'), error: false, mensaje: " "});

    React.useEffect(() => {

        if(eleccion){

            set_nombre({ valor:  eleccion.nombre, error: false, mensaje: " "});
            set_fecha({valor: moment(eleccion.fecha).format('YYYY-MM-DD'), error: false, mensaje: " "});
            
        }

    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();

        if(nombre.valor == "" ){
            set_nombre({...nombre, error: true, mensaje: "nombre es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(fecha.valor == "" ){
            set_fecha({...fecha, error: true, mensaje: "fecha es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        registrar_eleccion();

    }

    const registrar_eleccion = () => {

        set_procesando(true);

        let ENDPOINT = eleccion ? "api/elecciones/" + eleccion.id : "api/elecciones";
        let METODO = eleccion ?  "PUT" : "POST" ;
        let DATOS = { 
            nombre: nombre.valor,
            fecha: fecha.valor
        };

        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            if( eleccion == null ){

                set_nombre({ valor:  "", error: false, mensaje: " "});
                set_fecha({valor: moment().format('YYYY-MM-DD'), error: false, mensaje: " "});
        
            }
           
            set_notificacion({ mensaje: "data registrada con exito", tipo: "success" } );
            
            set_procesando(false);

            recargar_elecciones();
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
            set_procesando(false);

        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);


    }


    return(
        <Box component="form" noValidate onSubmit={handleSubmit}>
             <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
            <AppBar position="static">
                <Toolbar>
                
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Elección
                </Typography>
                
                </Toolbar>
            </AppBar>
            </Box>

            <Divider>
            <Typography variant="subtitle2" gutterBottom component="div" mt={1}>
                Datos Básicos
            </Typography>
            </Divider>
            
            <Grid container spacing={2}>

                <Grid item xs={12} md={6}>
                <TextField
                    error={nombre.error}
                    helperText={nombre.mensaje}
                    
                    required
                    fullWidth
                    name="nombre"
                    label="Nombre"
                    type="text"
                    id="nombre"
                    value={nombre.valor}
                    onChange={ (event) => set_nombre({ valor: event.target.value, error: false, mensaje: " "}) }
                    
                    />
                    
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                    error={fecha.error}
                    helperText={fecha.mensaje}
                    
                    required
                    fullWidth
                    name="fecha"
                    label="Fecha"
                    type="date"
                    id="fecha"
                    value={fecha.valor}
                    onChange={ (event) => set_fecha({ valor: event.target.value, error: false, mensaje: " "}) }
                    
                    />
                
                </Grid>
                  
                
            </Grid>

            <Toolbar>
            <Box sx={{ flexGrow: 1 }}></Box>

            <Box>
            <Button
                type="submit" 
                variant="contained"
                sx={{ mt: 1, mb: 1 }}
                >
                REGISTRAR
            </Button>
            <Box sx={{ width: '100%', visibility: (procesando ? 'visible' : 'hidden') }}>
                <LinearProgress />
            </Box>
            </Box>
            </Toolbar>

            <Snackbar
                open={notificacion.mensaje != ""}
                autoHideDuration={6000}
                onClose={cerrar_notificacion}
            
            
            >
                <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
                {notificacion.mensaje}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default FormEleccion;