import React from "react";
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';

const PaginaNoEncontrada = () => {

    return(    
       
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                backgroundColor= "primary.light"
                >

            <Paper elevation={3} >
            <Card >
                <CardMedia
                    component="img"
                    height="281"
                    image="/images/pagina_404.jpg"
                    alt="404"
                />

                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" align="center">
                        Página No Encontrada
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center">
                        Este recurso no se encuentra en nuestro sistema.
                    </Typography>
                </CardContent>
            
            </Card>
            </Paper>

            </Box>
     
    );

}

export default PaginaNoEncontrada;