import * as React from "react";
import SignIn from './SignIn';
import DataContext from './context/DataContext';
import { Routes, Route, Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import PaginaNoEncontrada from "./components/PaginaNoEncontrada";

import AdministradorPanel from "./components/AdministradorPanel";
import CoordinadorPanel from "./components/CoordinadorPanel";
import MultiplicadorPanel from "./components/MultiplicadorPanel";
import RegistradorPanel from "./components/RegistradorPanel";
import VerificadorPanel from "./components/VerificadorPanel";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/x-data-grid';

const theme = createTheme({
	palette: {
		primary: { main: '#31A2AC' },
		secondary: { main: '#0278AE' }	
	}
}, esES);



const RequireAuth = ({ children }) => {

	const { data } = React.useContext(DataContext);

	let location = useLocation();

	console.log(data.user)

	if (!data.user) {
		
	    return (<Navigate to="/" state={{ from: location }} replace />);
	}

	if (data.user && data.user.rol == "ADMINISTRADOR" && !location.pathname.startsWith("/administrador") ) {
		
	    return (<Navigate to="/administrador" state={{ from: location }} replace />);
	}

	if (data.user && data.user.rol == "COORDINADOR" && !location.pathname.startsWith("/coordinador") ) {
		
	    return (<Navigate to="/coordinador" state={{ from: location }} replace />);
	}

	if (data.user && data.user.rol == "MULTIPLICADOR" && !location.pathname.startsWith("/multiplicador") ) {
		
	    return (<Navigate to="/multiplicador" state={{ from: location }} replace />);
	}

	if (data.user && data.user.rol == "REGISTRADOR" && !location.pathname.startsWith("/registrador") ) {
		
	    return (<Navigate to="/registrador" state={{ from: location }} replace />);
	}

	if (data.user && data.user.rol == "VERIFICADOR" && !location.pathname.startsWith("/verificador") ) {
		
	    return (<Navigate to="/verificador" state={{ from: location }} replace />);
	}


	return children;
}


const App = () => {

	const [data, set_data] = React.useState({ user:  JSON.parse(localStorage.getItem('user')) });

	const sing_in = (user) => {

		localStorage.setItem('user', JSON.stringify(user));
		set_data({...data, user });

	}

	const sing_out = () => {

		localStorage.removeItem('user');
		set_data({...data, user: null });
		

	}
 	
    return (
	    <ThemeProvider theme={theme}>
	    <DataContext.Provider value={{ data, sing_in, sing_out }}>
		
	      <Routes>
	     

	        <Route path="/" element={<SignIn />} />
	        <Route path="/administrador/*" element={<RequireAuth><AdministradorPanel /></RequireAuth>}  />
			<Route path="/coordinador/*" element={<RequireAuth><CoordinadorPanel /></RequireAuth>}  />
			<Route path="/multiplicador/*" element={<RequireAuth><MultiplicadorPanel /></RequireAuth>}  />
			<Route path="/registrador/*" element={<RequireAuth><RegistradorPanel /></RequireAuth>}  />
			<Route path="/verificador/*" element={<RequireAuth><VerificadorPanel /></RequireAuth>}  />
	        <Route path="*" element={<PaginaNoEncontrada />} />   
	        
	      
	      </Routes>
		
	    </DataContext.Provider >
		</ThemeProvider>
	   
    );
}

export default App;