import React from 'react';
import Box from '@mui/material/Box';

import DataContext from '../../context/DataContext';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import API from '../../api/API';

import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/People';

import { DataGrid, GridToolbar, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector } from '@mui/x-data-grid';

import { useNavigate, useLocation } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getSelectedRowsToExport = ({ apiRef }) => { const selectedRowIds = selectedGridRowsSelector(apiRef); if (selectedRowIds.size > 0) { return Array.from(selectedRowIds.keys()); } return gridFilteredSortedRowIdsSelector(apiRef); };

const CoordinadoresConteoAfiliadosAsistentes = ({eleccion}) => {

    let navigate = useNavigate();

    const columns = [    
        { field: 'nombre', minWidth: 150, headerName: 'Nombre' },
        { field: 'apellido', minWidth: 150, headerName: 'Apellido' },
        { field: 'cedula', minWidth: 150, headerName: 'Cédula'  },
        { field: 'correo', minWidth: 150, headerName: 'Correo' },
        { field: 'celular', minWidth: 150, headerName: 'Celular' },
        { field: 'meta', minWidth: 50, headerName: 'Meta', type: 'number' },
        { field: 'fecha_limite', minWidth: 150, headerName: 'Fecha Limite' },
        { field: 'afiliados', minWidth: 50, headerName: 'Afiliados', type: 'number' },
        { field: 'asistencias', minWidth: 50, headerName: 'Asistentes', type: 'number' },
        { 
            field: 'id',
            headerName: 'Porcentaje',
            renderCell: (params) => ( params.row.asistencias == 0 ? 0 : (params.row.asistencias / params.row.afiliados) * 100 ) + " %",

        },
        { 
            field: 'electores_afiliados',
            disableExport: true,
            type: 'actions', 
            headerName: 'Ver Afiliados',
            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={(event) => navigate('/administrador/reporte_asistencias', { state: { coordinador_id: params.row.id, eleccion_id: eleccion.id }}) } > <PeopleIcon /> </IconButton>,

        },
    ];

    const { data } = React.useContext(DataContext);


    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const [isLoading, setIsLoading] = React.useState(false);

    const cerrar_notificacion = (event, reason) => set_notificacion({...notificacion, mensaje: ""});
    

    const [coordinadores, set_coordinadores] = React.useState([]);


    React.useEffect( () => {

        buscar_coordinadores_conteo_afiliados_asistentes_por_eleccion();

    }, []);

    const buscar_coordinadores_conteo_afiliados_asistentes_por_eleccion = () => {

        let ENDPOINT = "api/coordinadores/conteo/afiliados/asistentes/eleccion/" + eleccion.id;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_coordinadores(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const [paginationModel, setPaginationModel] = React.useState({ pageSize: 10, page: 0 });

    return(
        <Box component="div" style={{ height: '80%' }}>
            <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
            <AppBar position="static">
                <Toolbar>
                
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Asistencias Por Coordinador
                </Typography>
                       
                </Toolbar>
            </AppBar>
            </Box>

            <DataGrid  
                rows={coordinadores}
                columns={columns}
                pageSizeOptions={[10, 20, 30]}
                checkboxSelection
                disableRowSelectionOnClick 
                loading={isLoading}
                slots={{ toolbar: GridToolbar }} 
                slotProps={{ toolbar: { printOptions: { hideFooter: false, hideToolbar: true, getRowsToExport: getSelectedRowsToExport }, csvOptions: { fileName: 'Colegios', delimiter: ';', utf8WithBom: true } } }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pagination
                autoHeight
            />

        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </Box>

    );
}

export default CoordinadoresConteoAfiliadosAsistentes;
