import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HailIcon from '@mui/icons-material/Hail';
import FactCheckIcon from '@mui/icons-material/FactCheck';

import { useNavigate, useLocation } from "react-router-dom";

export const RegistradorMenu =  () => {

  let location = useLocation();
  let navigate = useNavigate();

  React.useEffect(() => {

    if(location.pathname == "/registrador"){
      set_indice(0);
    }

    if(location.pathname == "/registrador/elecciones"){
      set_indice(1);
    }

    if(location.pathname == "/registrador/reporte_asistencias"){
      set_indice(2);
    }

  }, [location]);

  const [indice, set_indice] = React.useState(1);

  const handleListItemClick = (event, index, path) => {
    set_indice(index);
    navigate(path);
  };
  
  return(
    <React.Fragment>

      {/* <ListItemButton selected={indice === 0} onClick={ (event) => handleListItemClick(event, 0, '/registrador') }>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Resumen" />
      </ListItemButton> */}

      <ListItemButton selected={indice === 1} onClick={ (event) => handleListItemClick(event, 1, '/registrador/elecciones') }>
        <ListItemIcon>
          <HailIcon />
        </ListItemIcon>
        <ListItemText primary="Elecciones" />
      </ListItemButton>

      <ListItemButton selected={indice === 2} onClick={ (event) => handleListItemClick(event, 2, '/registrador/reporte_asistencias') }>
        <ListItemIcon>
          <FactCheckIcon />
        </ListItemIcon>
        <ListItemText primary="Reporte Asistencias" />
      </ListItemButton>

    </React.Fragment>
  );

};

