import  React from 'react';
import { DataGrid, GridToolbar, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import FormMovimiento from './FormMovimiento';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DataContext from '../../context/DataContext';
import API from '../../api/API';

import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PeopleIcon from '@mui/icons-material/People';

import { useNavigate, useLocation } from "react-router-dom";

const getSelectedRowsToExport = ({ apiRef }) => { const selectedRowIds = selectedGridRowsSelector(apiRef); if (selectedRowIds.size > 0) { return Array.from(selectedRowIds.keys()); } return gridFilteredSortedRowIdsSelector(apiRef); };

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '80vh',
    overflow : 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Movimientos = () => {

    const { data } = React.useContext(DataContext);
    let navigate = useNavigate();

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const cerrar_notificacion = (event, reason) => set_notificacion({...notificacion, mensaje: ""});

    const [isLoading, setIsLoading] = React.useState(false);

    const [open_formulario_movimiento, set_open_formulario_movimiento] = React.useState(false);
 
    const handle_open_formulario_movimiento = () => set_open_formulario_movimiento(true);
    const handle_close_formulario_movimiento = () => {set_open_formulario_movimiento(false); set_movimiento_elegido(null);};

    const [movimiento_elegido, set_movimiento_elegido] = React.useState(null);

    React.useEffect( () => {

        if( movimiento_elegido != null && movimiento_elegido.accion == 'modificar_movimiento' ){

            handle_open_formulario_movimiento();

        }

    }, [movimiento_elegido]);

    const columns = [     
        { field: 'nombre', minWidth: 300, headerName: 'Nombre' },
        { field: 'cantidad_afiliados', minWidth: 200, headerName: 'Cantidad de Afiliados', type: 'number' },
       
        { 
            field: 'afiliados',
            disableExport: true,
            type: 'actions', 
            headerName: 'Ver Afiliados',
            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={(event) => navigate('/administrador/reporte_afiliados', { state: { movimiento_id: params.row.id }}) } > <PeopleIcon /> </IconButton>,

        },
        { 
            field: 'modificar',
            type: 'actions', 
            headerName: 'Editar',
            disableExport: true,
            minWidth: 150,

            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={ (event) =>  set_movimiento_elegido({...params.row, accion: 'modificar_movimiento'}) } > <ModeEditIcon /> </IconButton>,

        },
    ];


    const [movimientos, set_movimientos] = React.useState([]);

    React.useEffect( () => {

        buscar_movimientos();

    }, []);
    
    const buscar_movimientos = () => {

        setIsLoading(true);

        let ENDPOINT = "api/administrador/movimientos";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_movimientos(respuesta);

            setIsLoading(false);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );

            setIsLoading(false);

        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const [paginationModel, setPaginationModel] = React.useState({ pageSize: 10, page: 0 });

    return(
        <div style={{  height: '70%' }}>
        <div style={{ flexGrow: 1 }}>

            <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <Fab color="primary" variant="extended" onClick={handle_open_formulario_movimiento}>
                <AddIcon />
                Agregar Movimiento
            </Fab>
            </Box>

            <DataGrid
                rows={movimientos}
                columns={columns}
                pageSizeOptions={[10, 20, 30, 100]}
                checkboxSelection
                disableRowSelectionOnClick 
                slots={{ toolbar: GridToolbar }} 
                slotProps={{ toolbar: { printOptions: { hideFooter: false, hideToolbar: true, getRowsToExport: getSelectedRowsToExport }, csvOptions: { fileName: 'Coordinadores', delimiter: ';', utf8WithBom: true } } }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                loading={isLoading}
                pagination
                autoHeight
               />
            
            <Modal
                open={open_formulario_movimiento}
                onClose={handle_close_formulario_movimiento}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                
                <FormMovimiento recargar_movimientos={buscar_movimientos} movimiento={movimiento_elegido} />

                </Box>
            </Modal>

     

       

        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </div>
        </div>
    );
}

export default Movimientos;