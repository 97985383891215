import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DataContext from '../../context/DataContext';
import InputMask from 'react-input-mask';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import API from '../../api/API';
import CanvasDraw from "react-canvas-draw";
import LinearProgress from '@mui/material/LinearProgress';
import * as axios from 'axios';
import Chip from '@mui/material/Chip';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FormRegistrarFirma = ({ recargar_afiliados, afiliado }) => {

    const { data } = React.useContext(DataContext);

    let canvasRef = React.useRef(null);

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const [procesando, set_procesando] = React.useState(false);

    const cerrar_notificacion = () => set_notificacion({...notificacion, mensaje: ""});


    const handleSubmit = (event) => {

        event.preventDefault();

        let firma =  canvasRef.getDataURL().replace('data:image/png;base64,', '');

        registrar_firma(firma);

    }

    const registrar_firma = (firma) => {

        set_procesando(true);

        let ENDPOINT = `api/firma/${afiliado.id}`;
        let METODO = "PUT";
        let DATOS = { 
            firma
        };
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
            
         
            set_notificacion({ mensaje: "firma registrada con éxito.", tipo: "success" } ); 
            set_procesando(false);

            recargar_afiliados();

        };

        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
            set_procesando(false);

        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }



    return(
        <Box component="form" noValidate onSubmit={handleSubmit}>
            <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
            <AppBar position="static">
                <Toolbar>
                
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Firmar - {afiliado.nombres} {afiliado.apellidos} - {afiliado.cedula}
                </Typography>
                     
                </Toolbar>
            </AppBar>
            </Box>

            <Grid container spacing={2}  justifyContent="center">
            <Grid item xs={12} md={6} >
            <Box sx={{  
                        width: '100%', 
                        borderWidth: 1, 
                        borderStyle: 'solid', 
                        borderColor: 'gray', 
                        marginTop: 3,
                        display: 'flex', 
                        justifyContent: 'center',
                        backgroundColor: 'gray'

                    }}>
            <CanvasDraw
                brushColor='#000000'
                lazyRadius={0}
                brushRadius={1}
                canvasWidth={200}
                canvasHeight={200}
                ref={ r => canvasRef = r }
            />
            </Box>  
            </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={3} >
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
            <Chip label="Deshacer" onClick={ () => canvasRef.undo() }/>
            </Box>
            </Grid>

            <Grid item xs={12} md={3}>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
            <Chip label="Limpiar" onClick={ () => canvasRef.clear() }/>
            </Box> 
            </Grid>
            </Grid>

        

        <Toolbar>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box>
        <Button
              type="submit" 
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
            REGISTRAR
        </Button>
        <Box sx={{ width: '100%', visibility: (procesando ? 'visible' : 'hidden') }}>
            <LinearProgress />
        </Box>
        </Box>
        </Toolbar>

        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </Box>
    );
}

export default FormRegistrarFirma;