import * as React from 'react';
import { DataGrid, GridToolbar, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


import Avatar from '@mui/material/Avatar';


import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DataContext from '../../context/DataContext';
import API from '../../api/API';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Modal from '@mui/material/Modal';
import FotoElector from '../FotoElector';

import { useNavigate, useLocation } from "react-router-dom";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const ReporteAfiliados = () => {

    let location = useLocation();
    
    const { data } = React.useContext(DataContext);

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const [isLoading, setIsLoading] = React.useState(false);

    const cerrar_notificacion = (event, reason) => set_notificacion({...notificacion, mensaje: ""});

    const [open_foto_elector, set_open_foto_elector] = React.useState(false);
    const [cedula_foto, set_cedula_foto] = React.useState("");

    const [afiliados, set_afiliados] = React.useState([]);
   
    const [multiplicadores, set_multiplicadores] = React.useState([]);
    const [multiplicador, set_multiplicador] = React.useState(null);
    const [multiplicador_id, set_multiplicador_id] = React.useState(null);
    const [input_multiplicador, set_input_multiplicador] = React.useState('');

    
    React.useEffect( () => {

        if(cedula_foto != "" ){
            set_open_foto_elector(true);
        }else{
            set_open_foto_elector(false)
        }

    }, [cedula_foto]);


    const columns = [  
        { field: 'multiplicador_id', headerName: 'Id Mult.', type: 'number' },

        { 
            field: 'firma', 
            disableExport: true,
            minWidth: 100, 
            headerName: 'Firma',
            renderCell: ( params) => { 
                
                return( <Avatar sx={{ width: 80, height: 80 }} variant="square" alt="Firma" src={"data:image/png;base64," + (params.row.firma || "")} />  );

            } 
        },  
        
        { field: 'fecha_registro', minWidth: 150, headerName: 'Fecha de Registro' },

        { 
            field: 'foto', 
            disableExport: true,
            minWidth: 100, 
            headerName: 'Foto',
            renderCell: ( params) => { 
                
                return( <Avatar onClick={ (event) => set_cedula_foto(params.row.cedula) } variant="square" alt="Foto" src={`https://img.sistemasmicronetdom.work/${params.row.cedula}.jpg`} />  );

            } 
        },
        { 
            field: 'verificado', 
            minWidth: 50, 
            headerName: '¿Verificado?',
            valueFormatter: ({ value }) => value,
            renderCell: ( params) => { 
                
                return( (params.row.verificado == "SI" ? <strong style={{ color: "green" }}>Si</strong> : <strong style={{ color: "red" }}>No</strong>) );

            } 
        },   
        { field: 'cedula', minWidth: 150, headerName: 'Cédula' }, 
        { field: 'nombres', minWidth: 150, headerName: 'Nombres' },
        { field: 'apellidos', minWidth: 150, headerName: 'Apellidos' },
        { field: 'provincia_nombre', minWidth: 150, headerName: 'Provincia' }, 
        { field: 'municipio_nombre', minWidth: 150, headerName: 'Municipio' },
        { field: 'direccion', minWidth: 150, headerName: 'Dirección' },
        { field: 'nacionalidad_nombre', minWidth: 150, headerName: 'Nacionalidad' },
        { field: 'sexo', minWidth: 150, headerName: 'Sexo' },
        { field: 'telefono', minWidth: 150, headerName: 'Teléfono' },

        { field: 'elector_colegio_nombre', minWidth: 150, headerName: 'Colegio' },
        { field: 'elector_colegio_codigo', minWidth: 150, headerName: 'Código Colegio' },
        { field: 'elector_colegio_municipio_nombre', minWidth: 150, headerName: 'Municipio Colegio' },
        { field: 'elector_colegio_recinto_nombre', minWidth: 150, headerName: 'Recinto' },
        { field: 'elector_colegio_recinto_codigo', minWidth: 150, headerName: 'Código Recinto' },
        { field: 'elector_colegio_recinto_direccion', minWidth: 300, headerName: 'Dirección Recinto' },
        { field: 'elector_recinto_circunscripcion_nombre', minWidth: 150, headerName: 'Circunscripción' },
        { field: 'elector_recinto_circunscripcion_codigo', minWidth: 150, headerName: 'Código Circunscripción' },
        { field: 'elector_municipio_provincia_nombre', minWidth: 150, headerName: 'Provincia Circunscripción' },

      
       
    ];


    React.useEffect( () => {

        buscar_multiplicadores();

        if(location.state && location.state.multiplicador_id) {
            set_multiplicador_id(location.state.multiplicador_id)
        }

    }, []);


    React.useEffect( () => {

        if(multiplicador) {
            buscar_reporte_afiliados_por_multiplicador(multiplicador.id);
        }else{
            set_afiliados([]);
        }
        
    }, [multiplicador]);

    React.useEffect( () => {

        if(multiplicador_id && multiplicadores.length > 0) {
            set_multiplicador(multiplicadores.find( e => e.id == multiplicador_id));
        }else{
            set_multiplicador(null);
        }

    }, [multiplicador_id, multiplicadores]);


    const buscar_multiplicadores = () => { 

        let ENDPOINT = `api/coordinador/multiplicadores`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
        
            set_multiplicadores(respuesta);       
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_reporte_afiliados_por_multiplicador = (el_multiplicador_id) => { 

        setIsLoading(true);

        let ENDPOINT = `api/coordinador/reporte_afiliados/multiplicador/${el_multiplicador_id}`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            setIsLoading(false);

            set_afiliados(respuesta);       
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            setIsLoading(false);
            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const [paginationModel, setPaginationModel] = React.useState({ pageSize: 10, page: 0 });

    return(
        <div style={{ height: '80%' }}>
        <div style={{ flexGrow: 1 }}>

        <Box sx={{ '& > :not(style)': { marginY: 3 } }}>

        <Grid container spacing={0}>

        <Grid item xs={12} md={4}>

        <Autocomplete
            autoHighlight
            getOptionLabel={(option) => option.nombre + " " + option.apellido + " - " + option.cedula}
            value={multiplicador}
            onChange={(event, newValue) => {

                set_multiplicador_id(newValue ? newValue.id : null);

            }}
            inputValue={input_multiplicador}
            onInputChange={(event, newInputValue) => {

                set_input_multiplicador(newInputValue);

            }}
            options={multiplicadores}
            sx={{ flex: 1, marginRight: 3 }}
            renderInput={(params) => <TextField {...params} label="Multiplicador" fullWidth size="small" />}

        />

        </Grid>

        <Grid item xs={12} md={4}>


        </Grid>

        <Grid item xs={12} md={4}>

        </Grid>

        </Grid>

        </Box>

        <DataGrid
            rows={afiliados}
            columns={columns}
            columnVisibilityModel={{
                multiplicador_id: false
            }}
            pageSizeOptions={[10, 20, 30]}
            checkboxSelection
            disableRowSelectionOnClick 
            slots={{ toolbar: GridToolbar }} 
            slotProps={{ toolbar: { printOptions: { hideFooter: false, hideToolbar: true }, csvOptions: { fileName: 'Afiliados', delimiter: ';', utf8WithBom: true } } }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            loading={isLoading}
            pagination
            autoHeight
            
        />


        <Modal
            open={open_foto_elector}
            onClose={ () => set_cedula_foto("") }
            
            >
            <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    height: 400,
                    overflow : 'auto',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
            
            <FotoElector cedula_foto={cedula_foto} />

            </Box>
        </Modal>
        
            
        
        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </div>
        </div>
    );
}

export default ReporteAfiliados;