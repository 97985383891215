import * as axios from 'axios';

const API = {};

// API.url = 'http://127.0.0.1:3708/';
API.url = 'https://afiliados-mas-api.sistemasmicronetdom.work/';

API.solicitud = (endpoint, metodo,  datos = null, respuesta_exito, respuesta_error , header = null) => {

	switch (metodo) {
        case "GET":

          	 axios.get(API.url + endpoint, header)
		    .then( (response) => {
		    	
		      	respuesta_exito(response.data);

		    })
		    .catch( (error) => {

		       	switch (error.response.status) {
		          case 400:
		           respuesta_error(error.response.data[0].msg);
		            break;
		          case 401:
		           respuesta_error(error.response.data.msg);
		            break;
		          case 403:
		           respuesta_error(error.response.data.msg);
		            break;
		          case 500:
		           respuesta_error(error.response.data.msg);
		            break;
		          default:
		           respuesta_error("error al realizar petición");
		            
		        }	
		        
		        
		    });
            
            break;

		case "GET_DOWNLOAD":

				axios.get(API.url + endpoint, header)
			 .then( (response) => {
				 
				   respuesta_exito(response);
 
			 })
			 .catch( (error) => {
 
					switch (error.response.status) {
				   case 400:
					respuesta_error(error.response.data[0].msg);
					 break;
				   case 401:
					respuesta_error(error.response.data.msg);
					 break;
				   case 403:
					respuesta_error(error.response.data.msg);
					 break;
				   case 500:
					respuesta_error(error.response.data.msg);
					 break;
				   default:
					respuesta_error("error al realizar petición");
					 
				 }	
				 
				 
			 });
			 
			 break;

        case "POST":

          	axios.post(API.url + endpoint, datos, header)
		    .then( ( response ) => {

		        respuesta_exito(response.data);	   

		    })
		    .catch( ( error ) => {


		        switch (error.response.status) {
		          case 400:
		           respuesta_error(error.response.data[0].msg);
		            break;
		          case 401:
		           respuesta_error(error.response.data.msg);
		            break;
		          case 403:
		           respuesta_error(error.response.data.msg);
		            break;
		          case 500:
		           respuesta_error(error.response.data.msg);
		            break;
		          default:
		           respuesta_error("error al realizar petición");
		            
		        }
		        
		    });
            
            break;

        case "PUT":

          	axios.put(API.url + endpoint, datos, header)
		    .then( ( response ) => {
	    
		        respuesta_exito(response.data);
	       
		    })
		    .catch( ( error ) => {

		    	
		        switch (error.response.status) {
		          case 400:
		           respuesta_error(error.response.data[0].msg);
		            break;
		          case 401:
		           respuesta_error(error.response.data.msg);
		            break;
		          case 403:
		           respuesta_error(error.response.data.msg);
		            break;
		          case 500:
		           respuesta_error(error.response.data.msg);
		            break;
		          default:
		           respuesta_error("error al realizar petición");
		            
		        }
		        
		    });
            
            break;

        case "DELETE":

          	 axios.delete(API.url + endpoint, header)
		    .then( (response) => {
	    	
		      	respuesta_exito(response.data);

		    })
		    .catch( (error) => {

		       	switch (error.response.status) {
		          case 400:
		           respuesta_error(error.response.data[0].msg);
		            break;
		          case 401:
		           respuesta_error(error.response.data.msg);
		            break;
		          case 403:
		           respuesta_error(error.response.data.msg);
		            break;
		          case 500:
		           respuesta_error(error.response.data.msg);
		            break;
		          default:
		           respuesta_error("error al realizar petición");
		            
		        }	
		        
		        
		    });
            
            break;

         default:

            respuesta_error("metodo http no existe");
         	break;
    
            
    }

	



}


export default API;