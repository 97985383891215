import React from "react";
import Grid from '@mui/material/Grid';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(CategoryScale, LinearScale, BarElement, Title);

export const data = {
    labels: ['Azua', 'Santo Domingo', 'Barahona', 'Dajabón', 'Duarte', 'El Seibo'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Afiliados del Año',
      },
    },
  };

  const labels = ['Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  export const data2 = {
    labels,
    datasets: [
      {
        label: 'Femenino',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Masculiono',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };


  export const data3 = {
    labels,
    datasets: [
      {
        label: 'Con Firma',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(48, 219, 63, 0.5)',
      },
      {
        label: 'Sin Firma',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(184, 22, 57, 0.5)',
      },
    ],
  };
  
  
  

const VerificadorResumen = () => {

	return(
        <div style={{  height: '70%' }}>
        <div style={{ flexGrow: 1 }}>
		    <Grid container spacing={2}>

            <Grid item xs={12} md={6}>
            <Pie data={data} />
            </Grid>

            <Grid item xs={12} md={6}>
            <Bar options={options} data={data2} style={{ width: '100%'}} />
            <Bar options={options} data={data3} style={{ width: '100%'}} />
            </Grid>

        </Grid>
        </div>
        </div>
	);
}


export default VerificadorResumen;