import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';


import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(CategoryScale, LinearScale, BarElement, Title);

export const data = {
    labels: ['Azua', 'Santo Domingo', 'Barahona', 'Dajabón', 'Duarte', 'El Seibo'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Afiliados del Año',
      },
    },
  };

  const labels = ['Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  export const data2 = {
    labels,
    datasets: [
      {
        label: 'Femenino',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Masculiono',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };


  export const data3 = {
    labels,
    datasets: [
      {
        label: 'Con Firma',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(48, 219, 63, 0.5)',
      },
      {
        label: 'Sin Firma',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(184, 22, 57, 0.5)',
      },
    ],
  };
  
  
  

const CoordinadorResumen = () => {

  const [multiplicadores, set_multiplicadores] = React.useState([

    { id: 1, nombre: "Maria Lopez", afiliados: 3456 },
    { id: 2, nombre: "Jose Rojas", afiliados: 2200 },
    { id: 3, nombre: "Roberto Castro", afiliados: 2001 },
    { id: 4, nombre: "Sofia Perez", afiliados: 1987 },
    { id: 5, nombre: "Luis Blanco", afiliados: 1965 },
    { id: 6, nombre: "Miguel Bello", afiliados: 1678 },
    { id: 7, nombre: "Tania Contrera", afiliados: 1604 },
    { id: 8, nombre: "Victor Miranda", afiliados: 1200 },
    { id: 9, nombre: "Daniel Martinez", afiliados: 1178 },
    { id: 10, nombre: "Rodrigo Feliz", afiliados: 1165 },

  ])

  const [movimientos, set_movimientos] = React.useState([

    { id: 1, nombre: "Moviento Obrero", afiliados: 5678 },
    { id: 2, nombre: "Maestros Unidos", afiliados: 5200 },
    { id: 3, nombre: "Luchemos", afiliados: 4999 },
    { id: 4, nombre: "RD Unida", afiliados: 3987 },
    { id: 5, nombre: "Hacia El Futuro", afiliados: 3400 },
    { id: 6, nombre: "Un nuevo Camino", afiliados: 3399 },
    { id: 7, nombre: "Sindicatos", afiliados: 3200 },
    { id: 8, nombre: "Medicos Por RD", afiliados: 3127 },
    { id: 9, nombre: "Lineamiento", afiliados: 3122 },
    { id: 10, nombre: "Fuerza Del Pueblo", afiliados: 3101 },

  ])

  const [colegios, set_colegios] = React.useState([

    { id: 1, nombre: "ESCUELA BASICA LA PARED", afiliados: 6543 },
    { id: 2, nombre: "ANTIGUA ESC. PRIM. MANUEL U. GOMEZ    ", afiliados: 6234 },
    { id: 3, nombre: "ANTIGUO LICEO SECUNDARIO", afiliados: 5768 },
    { id: 4, nombre: "ASOCIACION PARA EL DESARROLLO", afiliados: 5701 },
    { id: 5, nombre: "ASOCIACION DOMINICANA DE REHABILITACION", afiliados: 5600 },
    { id: 6, nombre: "ARCHIVO GENERAL DE LA NACION", afiliados: 5478 },
    { id: 7, nombre: "CATEDRAL NUESTRA SEÑORA DEL ROSARIO", afiliados: 5345 },
    { id: 8, nombre: "CENTRO COMUNAL DR. JUAN AQUINO", afiliados: 5234 },
    { id: 9, nombre: "CENTRO CULTURAL BOCA CHICA", afiliados: 4999 },
    { id: 10, nombre: "CENTRO DE EDUCACIÓN INTEGRAL JARDlN DE COLORES", afiliados: 4789 },
  
  ])

  const [recintos, set_recintos] = React.useState([

    { id: 1, nombre: "ADMINISTRACION DE CORREOS ", afiliados: 12345 },
    { id: 2, nombre: "ALMACEN DE ELIGIO CACERES", afiliados: 11345 },
    { id: 3, nombre: "BIBLIOTECA MUNICIPAL", afiliados: 11120 },
    { id: 4, nombre: "BIBLIOTECA MUNICIPAL LUIS MAYRAND", afiliados: 10234 },
    { id: 5, nombre: "CASA AL LADO TALLER ELECTRICO (LOTESA)", afiliados: 10102 },
    { id: 6, nombre: "CASA DEL SEÑOR ETANISLAO FARIA SANCHEZ", afiliados: 10100 },
    { id: 7, nombre: "CASA DEL SR. FLORENTINO DURAN NOLASCO", afiliados: 10099 },
    { id: 8, nombre: "CENTRO CULTURAL BOCA CHICA", afiliados: 9890 },
    { id: 9, nombre: "CENTRO DE ESTUDIO HUERTO DEL EDEN", afiliados: 9789 },
    { id: 10, nombre: "CENTRO DE ESTUDIOS LINARES", afiliados: 9654 },

  ])

	return(
        <div style={{  height: '70%' }}>
        <div style={{ flexGrow: 1 }}>
        <Box sx={{ '& > :not(style)': { m: 6 } }}>
         
        </Box>
		    <Grid container spacing={2}>

            <Grid item xs={12} md={3}>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
              
                <Typography variant="h5" component="div">
                  7.370.029
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Total De Electores
                </Typography>
                
              </CardContent>
              <CardActions>

              
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

              <Box sx={{ flex: 1, width: '100%' }}>
                <LinearProgress variant="determinate" value={48} color="primary"/>
              </Box>

              <Box sx={{ flex: 1}}>
              <Typography variant="subtitle1" color="text.primary" textAlign={'center'} >
                3.544.906 Hombres
              </Typography>
              </Box>

              <Box sx={{ flex: 1}}>
              <Typography variant="subtitle1" color="text.primary" textAlign={'center'}  >
                3.845.123 Mujeres
              </Typography>
              </Box>

              </Box>
               
              </CardActions>
            </Card>
            </Grid>

            <Grid item xs={12} md={3}>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
              
                <Typography variant="h5" component="div">
                  98.145
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Total De Afiliados
                </Typography>
                
              </CardContent>
              <CardActions>

              
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

              <Box sx={{ flex: 1, width: '100%' }}>
                <LinearProgress variant="determinate" value={87} color="primary"/>
              </Box>

              <Box sx={{ flex: 1}}>
              <Typography variant="subtitle1" color="text.primary" textAlign={'center'}  >
                85.289 Hombres
              </Typography>
              </Box>

              <Box sx={{ flex: 1}}>
              <Typography variant="subtitle1" color="text.primary" textAlign={'center'}  >
                12.856 Mujeres
              </Typography>
              </Box>


              </Box>
               
              </CardActions>
            </Card>
            </Grid>

            <Grid item xs={12} md={3}>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
              
                <Typography variant="h5" component="div">
                  69.526
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Afiliados Que Parciparon
                </Typography>
                
              </CardContent>
              <CardActions>

              
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

              <Box sx={{ flex: 1, width: '100%' }}>
                <LinearProgress variant="determinate" value={60} color="primary"/>
              </Box>

              <Box sx={{ flex: 1}}>
              <Typography variant="subtitle1" color="text.primary" textAlign={'center'}  >
                40.523 Hombres
              </Typography>
              </Box>

              <Box sx={{ flex: 1}}>
              <Typography variant="subtitle1" color="text.primary" textAlign={'center'}  >
                28.003 Mujeres
              </Typography>
              </Box>


              </Box>
               
              </CardActions>
            </Card>
            </Grid>


            
            <Grid item xs={12} md={3}>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
              
                <Typography variant="h5" component="div">
                  28.619
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Afiliados Que No Parciparon
                </Typography>
                
              </CardContent>
              <CardActions>

              
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

              <Box sx={{ flex: 1, width: '100%' }}>
                <LinearProgress variant="determinate" value={37} color="primary"/>
              </Box>

              <Box sx={{ flex: 1}}>
              <Typography variant="subtitle1" color="text.primary" textAlign={'center'}  >
                10.723 Hombres
              </Typography>
              </Box>

              <Box sx={{ flex: 1}}>
              <Typography variant="subtitle1" color="text.primary" textAlign={'center'}  >
                17.896 Mujeres
              </Typography>
              </Box>


              </Box>
               
              </CardActions>
            </Card>
            </Grid>

           

        </Grid>

        <Grid container spacing={2} mt={3} >

        <Grid item xs={12} md={3}>

        <Card sx={{ minWidth: 275 }}>
          <CardContent>

          <Typography  variant="subtitle1" color="text.secondary">
            Top 10 Multiplicadores (# De Afiliados)
          </Typography>

          {
            multiplicadores.map( m => (

              <List key={m.id} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={m.nombre} secondary={m.afiliados + " Afiliados"} secondaryTypographyProps={{ color: 'primary'}} />
              </ListItem>
              </List>

                )
            )
          }

          </CardContent>

        </Card>

        </Grid>

        <Grid item xs={12} md={3}>

        <Card sx={{ minWidth: 275 }}>
          <CardContent>

          <Typography  variant="subtitle1" color="text.secondary">
            Top 10 Movimientos (# De Afiliados)
          </Typography>

          {
            movimientos.map( m => (

              <List key={m.id} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={m.nombre} secondary={m.afiliados + " Afiliados"} secondaryTypographyProps={{ color: 'primary'}} />
              </ListItem>
              </List>

                )
            )
          }

          </CardContent>

        </Card>

        </Grid>

        <Grid item xs={12} md={3}>

          <Card sx={{ minWidth: 275 }}>
            <CardContent>

            <Typography  variant="subtitle1" color="text.secondary">
              Top 10 Colegios (# De Afiliados )
            </Typography>

            {
              colegios.map( m => (

                <List key={m.id} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={m.nombre} secondary={m.afiliados + " Afiliados"} secondaryTypographyProps={{ color: 'primary'}} />
                </ListItem>
                </List>

                  )
              )
            }

            </CardContent>

          </Card>

          </Grid>

          <Grid item xs={12} md={3}>

            <Card sx={{ minWidth: 275 }}>
              <CardContent>

              <Typography  variant="subtitle1" color="text.secondary">
                Top 10 Recintos (# De Afiliados)
              </Typography>

              {
                recintos.map( m => (

                  <List key={m.id} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <ImageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={m.nombre} secondary={m.afiliados +  " Afiliados"} secondaryTypographyProps={{ color: 'primary'}} />
                  </ListItem>
                  </List>

                    )
                )
              }

              </CardContent>

            </Card>

            </Grid>

        </Grid>
        </div>
        </div>
	);
}


export default CoordinadorResumen;