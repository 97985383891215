import * as React from 'react';
import { DataGrid, GridToolbar, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DataContext from '../../context/DataContext';
import API from '../../api/API';

import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/People';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';

import { useNavigate, useLocation } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getSelectedRowsToExport = ({ apiRef }) => { const selectedRowIds = selectedGridRowsSelector(apiRef); if (selectedRowIds.size > 0) { return Array.from(selectedRowIds.keys()); } return gridFilteredSortedRowIdsSelector(apiRef); };

const Coordinadores = () => {

    const { data } = React.useContext(DataContext);

    let navigate = useNavigate();

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const cerrar_notificacion = (event, reason) => set_notificacion({...notificacion, mensaje: ""});


    const columns = [     
        { field: 'nombre', minWidth: 150, headerName: 'Nombre' },
        { field: 'apellido', minWidth: 150, headerName: 'Apellido' },
        { field: 'cedula', minWidth: 150, headerName: 'Cédula'  },
        { field: 'correo', minWidth: 150, headerName: 'Correo' },
        { field: 'movimiento_nombre', minWidth: 150, headerName: 'Movimiento' },
        { field: 'provincia_nombre', minWidth: 150, headerName: 'Provincia' }, 
        { field: 'municipio_nombre', minWidth: 150, headerName: 'Municipio' },
        { field: 'celular', minWidth: 150, headerName: 'Celular' },

        { 
            field: 'afiliados',
            disableExport: true,
            type: 'actions', 
            headerName: 'Ver Afiliados',
            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={ (event) => navigate('/verificador/reporte_afiliados', { state: { coordinador_id: params.row.id }}) } > <PeopleIcon /> </IconButton>,

        },
        { 
            field: 'multiplicadores',
            disableExport: true,
            type: 'actions', 
            headerName: 'Ver Multiplicadores',
            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={ (event) => navigate('/verificador/multiplicadores', { state: { coordinador_id: params.row.id }}) } > <RecordVoiceOverIcon /> </IconButton>,

        }
    ];


    const [coordinadores, set_coordinadores] = React.useState([]);

    React.useEffect( () => {

        buscar_coordinadores();

    }, []);
    
    const buscar_coordinadores = () => {

        let ENDPOINT = "api/verificador/coordinadores";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_coordinadores(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const [paginationModel, setPaginationModel] = React.useState({ pageSize: 10, page: 0 });

    return(
        <div style={{ height: '80%' }}>
        <div style={{ flexGrow: 1 }}>

            <Box sx={{ '& > :not(style)': { m: 1 } }}>
            
            </Box>

            <DataGrid
                rows={coordinadores}
                columns={columns}
                pageSizeOptions={[10, 20, 30, 100]}
                checkboxSelection
                disableRowSelectionOnClick 
                slots={{ toolbar: GridToolbar }} 
                slotProps={{ toolbar: { printOptions: { hideFooter: false, hideToolbar: true, getRowsToExport: getSelectedRowsToExport }, csvOptions: { fileName: 'Coordinadores', delimiter: ';', utf8WithBom: true } } }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pagination
                autoHeight
            />
    
        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </div>
        </div>
    );
}

export default Coordinadores;