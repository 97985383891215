import * as React from 'react';
import { DataGrid, GridToolbar, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import Modal from '@mui/material/Modal';
import FormRegistrador from './FormRegistrador';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DataContext from '../../context/DataContext';
import API from '../../api/API';

import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '80vh',
    overflow : 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const getSelectedRowsToExport = ({ apiRef }) => { const selectedRowIds = selectedGridRowsSelector(apiRef); if (selectedRowIds.size > 0) { return Array.from(selectedRowIds.keys()); } return gridFilteredSortedRowIdsSelector(apiRef); };

const Registradores = () => {

    const { data } = React.useContext(DataContext);

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const cerrar_notificacion = (event, reason) => set_notificacion({...notificacion, mensaje: ""});
    

    const [open_formulario_registrador, set_open_formulario_registrador] = React.useState(false);

    const handle_open_formulario_registrador = () => set_open_formulario_registrador(true);
    const handle_close_formulario_registrador = () => {set_open_formulario_registrador(false); set_registrador_elegido(null);}; 
    
    const [registrador_elegido, set_registrador_elegido] = React.useState(null);

    React.useEffect( () => {
       

        if( registrador_elegido != null && registrador_elegido.accion == 'modificar_registrador' ){

            handle_open_formulario_registrador();

        }

    }, [registrador_elegido]);

    const columns = [

        { field: 'cedula', minWidth: 150, headerName: 'Cédula'  },
        { field: 'nombre', minWidth: 150, headerName: 'Nombre' },
        { field: 'apellido', minWidth: 150, headerName: 'Apellido' },
        { field: 'correo', minWidth: 150, headerName: 'Correo' },
        { field: 'provincia_nombre', minWidth: 150, headerName: 'Provincia' }, 
        { field: 'municipio_nombre', minWidth: 150, headerName: 'Municipio' },
        { field: 'celular', minWidth: 150, headerName: 'Celular' },
        { 
            field: 'modificar',
            disableExport: true,
            type: 'actions', 
            headerName: 'Editar',
            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={(event) => { set_registrador_elegido({...params.row, accion: 'modificar_registrador'}); }} > <ModeEditIcon /> </IconButton>,

        },
    ];


    const [registradores, set_registradores] = React.useState([]);

    React.useEffect( () => {

        buscar_registradores();

    }, []);
    
    const buscar_registradores = () => {

        let ENDPOINT = "api/registradores";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_registradores(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const [paginationModel, setPaginationModel] = React.useState({ pageSize: 10, page: 0 });

    return(
        <div style={{ height: '80%' }}>
        <div style={{ flexGrow: 1 }}>

            <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <Fab color="primary" variant="extended" onClick={handle_open_formulario_registrador}>
                <AddIcon />
                Agregar Registrador
            </Fab>
            </Box>

            <DataGrid
                rows={registradores}
                columns={columns}
                pageSizeOptions={[10, 20, 30]}
                checkboxSelection
                disableRowSelectionOnClick 
                slots={{ toolbar: GridToolbar }} 
                slotProps={{ toolbar: { printOptions: { hideFooter: false, hideToolbar: true, getRowsToExport: getSelectedRowsToExport }, csvOptions: { fileName: 'Coordinadores', delimiter: ';', utf8WithBom: true } } }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pagination
                autoHeight
           />
            
            <Modal
                open={open_formulario_registrador}
                onClose={handle_close_formulario_registrador}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                
                <FormRegistrador recargar_registradores={buscar_registradores} registrador={registrador_elegido} />

                </Box>
            </Modal>

        
            
        
        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </div>
        </div>
    );
}

export default Registradores;