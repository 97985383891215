import * as React from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import InputMask from 'react-input-mask';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import API from './api/API';
import LinearProgress from '@mui/material/LinearProgress';
import DataContext from './context/DataContext';
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const SignIn = () => {

  let navigate = useNavigate();
  const { data, sing_in } = React.useContext(DataContext);

  React.useEffect( () => {

    if(data.user && data.user.rol == "ADMINISTRADOR" ){

      navigate("/administrador", { replace: true });

    }

    if(data.user && data.user.rol == "COORDINADOR" ){

      navigate("/coordinador", { replace: true });

    }

    if(data.user && data.user.rol == "MULTIPLICADOR" ){

      navigate("/multiplicador", { replace: true });

    }

    if(data.user && data.user.rol == "REGISTRADOR" ){

      navigate("/registrador", { replace: true });

    }

    if(data.user && data.user.rol == "VERIFICADOR" ){

      navigate("/verificador", { replace: true });

    }

  }, [data]);

  

  const [celular, set_celular] = React.useState("");
  const [contrasena, set_contrasena] = React.useState("");
  const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
  const [procesando, set_procesando] = React.useState(false);


  const handleSubmit = (event) => {
    event.preventDefault();

    if(celular == "" ){
      set_notificacion({ mensaje: "Debe ingresar celular.", tipo: "error" } );
      return;
    }

    if(contrasena == "" ){
      set_notificacion({ mensaje: "Debe ingresar contraseña.", tipo: "error" } );
      return;
    }

    set_procesando(true);

    let ENDPOINT = "api/ingresar_usuario";
    let METODO = "POST";
    let DATOS = { celular: celular.replace("-","").replace("-",""), contrasena };
    let RESPUESTA_EXITO = (respuesta) => {
      sing_in({...respuesta.usuario, token: respuesta.token });
      set_procesando(false);
     
    };
    let RESPUESTA_ERROR = (mensaje) => {
      set_notificacion({ mensaje, tipo: "error" } );
      set_procesando(false);
    };

    API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR);
     
  };

  const cerrar_notificacion = (event, reason) => {
    set_notificacion({ mensaje: "", tipo: "info" } );
  };

  return (
   
    <Box 
          display="flex" 
          height="100%" 
          backgroundColor="primary.main" 
          justifyContent="center" 
          alignItems="center" >
            
        <Container maxWidth="sm">
        <Paper elevation={3} >
        <Card >
        <CardMedia
                  component="img"
                  sx={{ height: 300, objectFit: 'contain' }}
                  image="/images/logo-afiliados2.jpg"
                  alt="404"
                  
                />
        <CardContent sx={{
           
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center',
           justifyContent: 'center', 
           
           
          }}>

         
         
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{
           
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center',
           justifyContent: 'center', 
           width: 180,

          }}>

            <InputMask
              mask="999-999-9999"
              value={celular}
              onChange={ (event) => set_celular(event.target.value) }
            >
              { () =>       
              <TextField
                margin="normal"
                required
                fullWidth
                id="celular"
                label="Celular"
                name="celular"
              
              /> 
              }
            </InputMask>


            
            <TextField
              margin="normal"
              required
              fullWidth
              name="contrasena"
              label="Contraseña"
              type="password"
              id="contrasena"
              value={contrasena}
              onChange={ (event) => set_contrasena(event.target.value) }
            
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
              INGRESAR
            </Button>

            <Box sx={{ width: '100%', visibility: (procesando ? 'visible' : 'hidden') }}>
              <LinearProgress />
            </Box>
            
            
           
          </Box>
        </CardContent>
        </Card>
        </Paper>
        </Container>
        
       
      <Snackbar
        open={notificacion.mensaje != ""}
        autoHideDuration={6000}
        onClose={cerrar_notificacion}
       
       
      >
        <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
        {notificacion.mensaje}
        </Alert>
      </Snackbar>
      </Box>
    
  );
}

export default SignIn;