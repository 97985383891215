import * as React from 'react';
import { DataGrid, GridToolbar, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DataContext from '../../context/DataContext';
import API from '../../api/API';

import { useNavigate, useLocation } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getSelectedRowsToExport = ({ apiRef }) => { const selectedRowIds = selectedGridRowsSelector(apiRef); if (selectedRowIds.size > 0) { return Array.from(selectedRowIds.keys()); } return gridFilteredSortedRowIdsSelector(apiRef); };

const ResumenPorColegios = () => {

    let location = useLocation();

    const estado_data_grid = location.state && location.state.elector_colegio_recinto_id ? {
        filter: {
        filterModel: {
            items: [ { field: 'elector_colegio_recinto_id', operator: '=', value: parseInt(location.state.elector_colegio_recinto_id) }],
        },
        },
    } : null;

    const { data } = React.useContext(DataContext);

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const [isLoading, setIsLoading] = React.useState(false);

    const cerrar_notificacion = (event, reason) => set_notificacion({...notificacion, mensaje: ""});
    
    const columns = [

        { field: 'elector_colegio_nombre', minWidth: 250, headerName: 'Colegio' },
        { field: 'elector_colegio_codigo', minWidth: 250, headerName: 'Código Colegio' },

        { field: 'elector_colegio_recinto_id', headerName: 'Id Recinto', type: 'number' },
        { field: 'elector_colegio_recinto_nombre', minWidth: 250, headerName: 'Recinto' },
        { field: 'elector_colegio_recinto_codigo', minWidth: 50, headerName: 'Código Recinto' },
        { field: 'elector_colegio_recinto_direccion', minWidth: 300, headerName: 'Dirección Recinto' },

        { field: 'elector_colegio_municipio_id', headerName: 'Id Municipio', type: 'number' },
        { field: 'elector_colegio_municipio_nombre', minWidth: 200, headerName: 'Municipio' },

        { field: 'elector_recinto_circunscripcion_id', headerName: 'Id Circunscripción', type: 'number' },
        { field: 'elector_recinto_circunscripcion_nombre', minWidth: 200, headerName: 'Circunscripción' },

        { field: 'elector_municipio_provincia_nombre', minWidth: 200, headerName: 'Provincia' },
      
        { field: 'cantidad_electores', minWidth: 200, headerName: 'Cantidad de Electores', type: 'number' },
        { field: 'cantidad_afiliados', minWidth: 200, headerName: 'Cantidad de Afiliados', type: 'number' },
    
    ];


    const [resumen_por_colegios, set_resumen_por_colegios] = React.useState([]);

    React.useEffect( () => {

        buscar_resumen_por_colegios();

    }, []);
    
    const buscar_resumen_por_colegios = () => {

        setIsLoading(true);

        let ENDPOINT = "api/resumen_por_colegios";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            setIsLoading(false);
  
            set_resumen_por_colegios(respuesta.map( (r, i) => { return({id: i, ...r})}));
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            setIsLoading(false);
            
            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const [paginationModel, setPaginationModel] = React.useState({ pageSize: 10, page: 0 });

    return(
        <div style={{ height: '80%' }}>
        <div style={{ flexGrow: 1 }}>

            <Box sx={{ '& > :not(style)': { m: 1 } }}>
            
            </Box>

            <DataGrid
                rows={resumen_por_colegios}
                columns={columns}
                columnVisibilityModel={{
                    elector_colegio_recinto_id: false,
                    elector_colegio_municipio_id: false,
                    elector_recinto_circunscripcion_id: false
                }}
                pageSizeOptions={[10, 20, 30]}
                checkboxSelection
                disableRowSelectionOnClick 
                slots={{ toolbar: GridToolbar }} 
                slotProps={{ toolbar: { printOptions: { hideFooter: false, hideToolbar: true, getRowsToExport: getSelectedRowsToExport }, csvOptions: { fileName: 'Colegios', delimiter: ';', utf8WithBom: true } } }}
                initialState={estado_data_grid}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                loading={isLoading}
                pagination
                autoHeight

            />
          

        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </div>
        </div>
    );
}

export default ResumenPorColegios;