import * as React from 'react';
import { DataGrid, GridToolbar, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import Modal from '@mui/material/Modal';
import FormMultiplicador from './FormMultiplicador';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DataContext from '../../context/DataContext';
import API from '../../api/API';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/People';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PieChartIcon from '@mui/icons-material/PieChart';

import { useNavigate, useLocation } from "react-router-dom";

import GrafAfiliadosMeta from './GrafAfiliadosMeta';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '80vh',
    overflow : 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const getSelectedRowsToExport = ({ apiRef }) => { const selectedRowIds = selectedGridRowsSelector(apiRef); if (selectedRowIds.size > 0) { return Array.from(selectedRowIds.keys()); } return gridFilteredSortedRowIdsSelector(apiRef); };

const Multiplicadores = () => {

    const { data } = React.useContext(DataContext);

    let navigate = useNavigate();
    let location = useLocation();

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const cerrar_notificacion = (event, reason) => set_notificacion({...notificacion, mensaje: ""});

    const [isLoading, setIsLoading] = React.useState(false);

    const [coordinadores, set_coordinadores] = React.useState([]);
    const [coordinador, set_coordinador] = React.useState(null);
    const [coordinador_id, set_coordinador_id] = React.useState(null);
    const [input_coordinador, set_input_coordinador] = React.useState('');
    
    const [open_formulario_multiplicador, set_open_formulario_multiplicador] = React.useState(false);

    const handle_open_formulario_multiplicador = () => set_open_formulario_multiplicador(true);
    const handle_close_formulario_multiplicador = () => { set_open_formulario_multiplicador(false); set_multiplicador_elegido(null); }; 
    
    const [open_meta_alcanzada, set_open_meta_alcanzada] = React.useState(false);

    const handle_open_meta_alcanzada = () => set_open_meta_alcanzada(true);
    const handle_close_meta_alcanzada = () => { set_open_meta_alcanzada(false); set_multiplicador_elegido(null); }; 
    

    const [multiplicador_elegido, set_multiplicador_elegido] = React.useState(null);

    React.useEffect( () => {
       

        if( multiplicador_elegido != null && multiplicador_elegido.accion == 'modificar_multiplicador' ){

            handle_open_formulario_multiplicador();

        }

        if( multiplicador_elegido != null && multiplicador_elegido.accion == 'meta_multiplicador' ){

            handle_open_meta_alcanzada();

        }

    }, [multiplicador_elegido]);

    const columns = [     
        { field: 'cedula', minWidth: 150, headerName: 'Cédula'  },
        { field: 'nombre', minWidth: 150, headerName: 'Nombre' },
        { field: 'apellido', minWidth: 150, headerName: 'Apellido' },
        { field: 'correo', minWidth: 150, headerName: 'Correo' },
        { field: 'provincia_nombre', minWidth: 150, headerName: 'Provincia' }, 
        { field: 'municipio_nombre', minWidth: 150, headerName: 'Municipio' },
        { field: 'celular', minWidth: 150, headerName: 'Celular' },
        { field: 'meta', minWidth: 150, headerName: 'Meta' },
        { field: 'fecha_limite', minWidth: 150, headerName: 'Fecha Límite' },

        { field: 'elector_colegio_nombre', minWidth: 150, headerName: 'Colegio' },
        { field: 'elector_colegio_codigo', minWidth: 150, headerName: 'Código Colegio' },
        { field: 'elector_colegio_municipio_nombre', minWidth: 150, headerName: 'Municipio Colegio' },
        { field: 'elector_colegio_recinto_nombre', minWidth: 150, headerName: 'Recinto' },
        { field: 'elector_colegio_recinto_codigo', minWidth: 150, headerName: 'Código Recinto' },
        { field: 'elector_colegio_recinto_direccion', minWidth: 300, headerName: 'Dirección Recinto' },
        { field: 'elector_recinto_circunscripcion_nombre', minWidth: 150, headerName: 'Circunscripción' },
        { field: 'elector_recinto_circunscripcion_codigo', minWidth: 150, headerName: 'Código Circunscripción' },
        { field: 'elector_municipio_provincia_nombre', minWidth: 150, headerName: 'Provincia Circunscripción' },

        { 
            field: 'afiliados',
            disableExport: true,
            type: 'actions', 
            headerName: 'Ver Afiliados',
            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={(event) => navigate('/administrador/reporte_afiliados', { state: { coordinador_id: coordinador_id, multiplicador_id: params.row.id }}) } > <PeopleIcon /> </IconButton>,

        },
        { 
            field: 'grafico',
            disableExport: true,
            type: 'actions', 
            headerName: 'Meta Alcanzada',
            minWidth: 150,

            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={(event) => set_multiplicador_elegido({...params.row, accion: 'meta_multiplicador'}) } > <PieChartIcon /> </IconButton>,

        },
        { 
            field: 'modificar',
            disableExport: true,
            type: 'actions', 
            headerName: 'Editar',
            renderCell: (params) => 
            <IconButton size="large" color="primary"  onClick={(event) => set_multiplicador_elegido({...params.row, accion: 'modificar_multiplicador'}) } > <ModeEditIcon /> </IconButton>,

        },
    ];


    const [multiplicadores, set_multiplicadores] = React.useState([]);

    React.useEffect( () => {

        buscar_coordinadores();

        if(location.state && location.state.coordinador_id) {
            set_coordinador_id(location.state.coordinador_id)
        }

    }, []);

    React.useEffect( () => {

        buscar_multiplicadores(coordinador_id);
        
    }, [coordinador]);

    React.useEffect( () => {

        if(coordinador_id && coordinadores.length > 0) {
            set_coordinador(coordinadores.find( c => c.id == coordinador_id ));
        }else{
            set_coordinador(null);
        }

    }, [coordinador_id, coordinadores]);

    const buscar_coordinadores = (el_coordinador_id) => { 

        let ENDPOINT = `api/administrador/coordinadores`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
        
            set_coordinadores(respuesta);       
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }
    
    const buscar_multiplicadores = (el_coordinador_id) => {

        setIsLoading(true);

        let ENDPOINT = `api/administrador/multiplicadores${ el_coordinador_id ? '/' + el_coordinador_id : '' }`;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_multiplicadores(respuesta);

            setIsLoading(false);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );

            setIsLoading(false);
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const [paginationModel, setPaginationModel] = React.useState({ pageSize: 10, page: 0 });

    return(
        <div style={{ height: '80%' }}>
        <div style={{ flexGrow: 1 }}>

            <Box sx={{ '& > :not(style)': { m: 3 } }}>

            <Grid container spacing={0}>

                <Grid item xs={12} md={4}>
                    <Fab color="primary" variant="extended" onClick={handle_open_formulario_multiplicador}>
                        <AddIcon />
                        Agregar Multiplicador
                    </Fab>
                </Grid>

                <Grid item xs={12} md={4}>

                    <Autocomplete
                        autoHighlight
                        getOptionLabel={(option) => option.nombre + " " + option.apellido + " - " + option.cedula}
                        value={coordinador}
                        onChange={(event, newValue) => {

                            set_coordinador_id(newValue ? newValue.id : null);

                        }}
                        inputValue={input_coordinador}
                        onInputChange={(event, newInputValue) => {

                            set_input_coordinador(newInputValue);

                        }}
                        options={coordinadores}
                        sx={{ flex: 1, marginRight: 3 }}
                        renderInput={(params) => <TextField {...params} label="Coordinador" fullWidth size="small" />}

                    />

                </Grid>

            </Grid>
            </Box>
            <DataGrid
                rows={multiplicadores}
                columns={columns}
                pageSizeOptions={[10, 20, 30, 100]}
                checkboxSelection
                disableRowSelectionOnClick 
                slots={{ toolbar: GridToolbar }} 
                slotProps={{ toolbar: { printOptions: { hideFooter: false, hideToolbar: true, getRowsToExport: getSelectedRowsToExport }, csvOptions: { fileName: 'Multiplicadores', delimiter: ';', utf8WithBom: true } } }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                loading={isLoading}
                pagination
                autoHeight
            />
            
            <Modal
                open={open_formulario_multiplicador}
                onClose={handle_close_formulario_multiplicador}
            >
                <Box sx={style}>
                
                <FormMultiplicador recargar_multiplicadores={buscar_multiplicadores} multiplicador={multiplicador_elegido} />

                </Box>
            </Modal>

            <Modal
                open={open_meta_alcanzada}
                onClose={handle_close_meta_alcanzada}
            >
                <Box sx={style}>
                
                <GrafAfiliadosMeta multiplicador={multiplicador_elegido} />

                </Box>
            </Modal>


        
            
        
        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </div>
        </div>
    );
}

export default Multiplicadores;