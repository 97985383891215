import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DataContext from '../../context/DataContext';
import InputMask from 'react-input-mask';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import API from '../../api/API';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import * as axios from 'axios';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FormAfiliado = ({ recargar_afiliados, afiliado, cedula_afiliar }) => {

    const { data } = React.useContext(DataContext);

    const [notificacion, set_notificacion] = React.useState({ mensaje: "", tipo: "info" });
    const [procesando, set_procesando] = React.useState(false);

    const cerrar_notificacion = () => set_notificacion({...notificacion, mensaje: ""});

    const [cedula, set_cedula] = React.useState({ valor: cedula_afiliar, error: false, mensaje: " "});
    const [nombres, set_nombres] = React.useState({ valor: "", error: false, mensaje: " "});
    const [apellidos, set_apellidos] = React.useState({ valor: "", error: false, mensaje: " "});
    
    const [telefono, set_telefono] = React.useState({ valor: "", error: false, mensaje: " "});

    const [provincias, set_provincias] = React.useState([]);
    const [provincia_id, set_provincia_id] = React.useState({ valor: "", error: false, mensaje: " "});

    const [municipios, set_municipios] = React.useState([]); 
    const [municipio_id, set_municipio_id] = React.useState({ valor: "", error: false, mensaje: " "});

    const [direccion, set_direccion] = React.useState({ valor: "", error: false, mensaje: " "});

    const [nacionalidades, set_nacionalidades] = React.useState([]);
    const [nacionalidad_id, set_nacionalidad_id] = React.useState({ valor: "", error: false, mensaje: " "});

    const [lugares_de_naciomiento, set_lugares_de_naciomiento] = React.useState([]);
    const [lugar_de_nacimiento_id, set_lugar_de_nacimiento_id] = React.useState({ valor: "", error: false, mensaje: " "});

    const [sexos, set_sexos] = React.useState([{ id: 'F', nombre: 'Femenino' }, { id: 'M', nombre: 'Masculino'}]);
    const [sexo_id, set_sexo_id] = React.useState({ valor: "", error: false, mensaje: " "});

    const [elector_colegio_id, set_elector_colegio_id] = React.useState(null);

    React.useEffect(() => {

        buscar_provincias();
        buscar_nacionalidades();
        buscar_lugares_de_naciomiento();

        if(afiliado){

            set_cedula({ valor: afiliado.cedula, error: false, mensaje: " "});
            set_nombres({ valor:  afiliado.nombres, error: false, mensaje: " "});
            set_apellidos({ valor: afiliado.apellidos, error: false, mensaje: " "});
            set_telefono({ valor: afiliado.telefono, error: false, mensaje: " "});
            set_provincia_id({ valor: afiliado.provincia_id, error: false, mensaje: " "});
            set_municipio_id({ valor: afiliado.municipio_id, error: false, mensaje: " "}); 
            set_direccion({ valor: afiliado.direccion, error: false, mensaje: " "});
            set_lugar_de_nacimiento_id({ valor: afiliado.lugar_de_nacimiento_id, error: false, mensaje: " "});
            set_nacionalidad_id({ valor: afiliado.nacionalidad_id, error: false, mensaje: " "});
            set_sexo_id({ valor: afiliado.sexo, error: false, mensaje: " "});
            set_elector_colegio_id(afiliado.elector_colegio_id);
            
        }else{

            set_cedula({ valor: cedula_afiliar, error: false, mensaje: " "});
            set_nombres({ valor:  "", error: false, mensaje: " "});
            set_apellidos({ valor: "", error: false, mensaje: " "});
            set_telefono({ valor: "", error: false, mensaje: " "});
            set_provincia_id({ valor: "", error: false, mensaje: " "});
            set_municipio_id({ valor: "", error: false, mensaje: " "});
            set_municipios([]);
            set_direccion({ valor: "", error: false, mensaje: " "});
            set_lugar_de_nacimiento_id({ valor: "", error: false, mensaje: " "});
            set_nacionalidad_id({ valor: "", error: false, mensaje: " "});
            set_sexo_id({ valor: "", error: false, mensaje: " "});
            set_elector_colegio_id(null);
        
        }

    }, []);

    React.useEffect(() => {

        set_municipios([]);

        if(provincia_id.valor){

            buscar_municipios(provincia_id.valor);

        }

    }, [provincia_id]);

    React.useEffect( () => { 

        let cedula_numeros = cedula.valor;
        cedula_numeros = cedula_numeros.replaceAll('_', '').replaceAll('-', '');
     
        if( cedula_numeros.length == 11 ){

            buscar_en_padron(cedula_numeros)

        }

    }, [cedula]);

    const buscar_en_padron = (la_cedula) => {

        axios.get(`https://padron-2024.sistemasmicronetdom.work/api/buscar_por_cedula/${la_cedula}`)
        .then( (response) => {

            if(response.data){

                set_nombres({ valor: response.data.nombres || "", error: false, mensaje: " "});
                set_apellidos({ valor: (response.data.apellido1 || "") + " " + (response.data.apellido2 || "" ), error: false, mensaje: " "} );
                set_provincia_id({ valor: (response.data.provincia_id || ""), error: false, mensaje: " " });
                set_municipio_id({ valor: (response.data.municipio_id || ""), error: false, mensaje: " " });
                set_nacionalidad_id({ valor: (response.data.nacionalidad_id || ""), error: false, mensaje: " " });        
                set_lugar_de_nacimiento_id({ valor: (response.data.lugar_de_nacimiento_id || ""), error: false, mensaje: " " });        
                set_sexo_id({ valor: (response.data.sexo || "" ), error: false, mensaje: " "});
                set_elector_colegio_id(response.data.elector_colegio_id || null);          

            }else{

                set_nombres({ valor:  "", error: false, mensaje: " "});
                set_apellidos({ valor: "", error: false, mensaje: " "});
                set_telefono({ valor: "", error: false, mensaje: " "});
                set_provincia_id({ valor: "", error: false, mensaje: " "});
                set_municipio_id({ valor: "", error: false, mensaje: " "});
                set_municipios([]);
                set_direccion({ valor: "", error: false, mensaje: " "});
                set_nacionalidad_id({ valor: "", error: false, mensaje: " "});
                set_lugar_de_nacimiento_id({ valor: "", error: false, mensaje: " "});
                set_sexo_id({ valor: "", error: false, mensaje: " "});
                set_elector_colegio_id(null);

                set_notificacion({ mensaje: "cédula no encontrada en el padron", tipo: "error" });

            }

        })
        .catch( (error) => {

            switch (error.response.status) {
                case 400:
                 set_notificacion({ mensaje: error.response.data[0].msg, tipo: "error" });
                break;
                case 401:
                 set_notificacion({ mensaje: error.response.data.msg, tipo: "error" });
                break;
                case 403:
                 set_notificacion({ mensaje: error.response.data.msg, tipo: "error" });
                break;
                case 500:
                 set_notificacion({ mensaje: error.response.data.msg, tipo: "error" });
                break;

                default:
                 set_notificacion({ mensaje: "error al realizar petición", tipo: "error" });

            }	
            
        });
        

    }

    const buscar_provincias = () => {

        let ENDPOINT = "api/provincias";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_provincias(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_nacionalidades = () => {

        let ENDPOINT = "api/nacionalidades";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_nacionalidades(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_lugares_de_naciomiento = () => {

        let ENDPOINT = "api/paises";
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_lugares_de_naciomiento(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }

    const buscar_municipios = (la_provincia_id) => {

        let ENDPOINT = "api/municipios/"+la_provincia_id;
        let METODO = "GET";
        let DATOS = null;
        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {
  
            set_municipios(respuesta);
        
        };
        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
        
        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }


    const handleSubmit = (event) => {

        event.preventDefault();

        if(cedula.valor == "" ){
            set_cedula({...cedula, error: true, mensaje: "cédula es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(nombres.valor == "" ){
            set_nombres({...nombres, error: true, mensaje: "los nombres son requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(apellidos.valor == "" ){
            set_apellidos({...apellidos, error: true, mensaje: "los apellidos son requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(telefono.valor == "" ){
            set_telefono({...telefono, error: true, mensaje: "teléfono es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(provincia_id.valor == "" ){
            set_provincia_id({...provincia_id, error: true, mensaje: "provincia es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(municipio_id.valor == "" ){
            set_municipio_id({...municipio_id, error: true, mensaje: "municipio es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(direccion.valor == "" ){
            set_direccion({...direccion, error: true, mensaje: "dirección es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(nacionalidad_id.valor == "" ){
            set_nacionalidad_id({...nacionalidad_id, error: true, mensaje: "nacionalidad es requerida"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(lugar_de_nacimiento_id.valor == "" ){
            set_lugar_de_nacimiento_id({...lugar_de_nacimiento_id, error: true, mensaje: "lugar de nacimiento es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        if(sexo_id.valor == "" ){
            set_sexo_id({...sexo_id, error: true, mensaje: "sexo es requerido"});
            set_notificacion({ mensaje: "revisar data ingresada", tipo: "error" } );
            return;
        }

        registrar_afiliado();

    }

    const registrar_afiliado = () => {

        set_procesando(true);

        let ENDPOINT = afiliado ? "api/afiliados/" + afiliado.id : "api/afiliados";
        let METODO = afiliado ?  "PUT" : "POST" ;
        let DATOS = { 
            cedula: cedula.valor.replace("-", "").replace("-", ""),
            nombres: nombres.valor,
            apellidos: apellidos.valor,  
            telefono: telefono.valor.replace("-", "").replace("-", ""),
            provincia_id:  provincia_id.valor,
            municipio_id: municipio_id.valor,
            direccion: direccion.valor,
            nacionalidad_id: nacionalidad_id.valor,
            lugar_de_nacimiento_id: lugar_de_nacimiento_id.valor,
            sexo: sexo_id.valor,
            elector_colegio_id,
        };

        let HEADER = {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "Application/json",
                "x-access-token": data.user.token
            }  
        };

        let RESPUESTA_EXITO = (respuesta) => {

            if( afiliado == null ){

                set_cedula({ valor: "", error: false, mensaje: " "});
                set_nombres({ valor:  "", error: false, mensaje: " "});
                set_apellidos({ valor: "", error: false, mensaje: " "});
                set_telefono({ valor: "", error: false, mensaje: " "});
                set_provincia_id({ valor: "", error: false, mensaje: " "});
                set_municipio_id({ valor: "", error: false, mensaje: " "});
                set_municipios([]);
                set_direccion({ valor: "", error: false, mensaje: " "});
                set_nacionalidad_id({ valor: "", error: false, mensaje: " "});
                set_lugar_de_nacimiento_id({ valor: "", error: false, mensaje: " "});
                set_sexo_id({ valor: "", error: false, mensaje: " "});
                set_elector_colegio_id(null);
               
            }

           
            set_notificacion({ mensaje: "data registrada con exito", tipo: "success" } ); 
            set_procesando(false);

            recargar_afiliados();
        
        };

        let RESPUESTA_ERROR = (mensaje) => {

            set_notificacion({ mensaje, tipo: "error" } );
            set_procesando(false);

        };

        API.solicitud(ENDPOINT, METODO, DATOS, RESPUESTA_EXITO, RESPUESTA_ERROR, HEADER);

    }



    return(
        <Box component="form" noValidate onSubmit={handleSubmit}>
             <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
            <AppBar position="static">
                <Toolbar>
                
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Afiliado
                </Typography>
                     
                </Toolbar>
            </AppBar>
            </Box>

            <Typography variant="subtitle2" gutterBottom component="div" mt={1}>
                Datos Personales
            </Typography>
            <Divider />
            

      
            <Grid container spacing={2}>

            <Grid item xs={12} md={3}>
                <InputMask
                    mask="999-9999999-9"
                    value={cedula.valor}
                    onChange={ (event) => set_cedula({ valor: event.target.value, error: false, mensaje: " "}) }
                    >
                    { (props) =>       
                        <TextField
                            {...props}
                            error={cedula.error}
                            helperText={cedula.mensaje}
                            margin="normal"
                            required
                            fullWidth
                            label="Cédula"
                            type="text"

                        /> 
                    }
                </InputMask>    
            </Grid>

            <Grid item xs={12} md={3}>
                <TextField
                    error={nombres.error}
                    helperText={nombres.mensaje}
                    margin="normal"
                    required
                    fullWidth
                    label="Nombres"
                    type="text"
                    value={nombres.valor}
                    onChange={ (event) => set_nombres({ valor: event.target.value, error: false, mensaje: " "}) }
                    
                    />
                
            </Grid>

            <Grid item xs={12} md={3}>
                <TextField
                    error={apellidos.error}
                    helperText={apellidos.mensaje}
                    margin="normal"
                    required
                    fullWidth
                    label="Apellidos"
                    type="text"
                    value={apellidos.valor}
                    onChange={ (event) => set_apellidos({ valor: event.target.value, error: false, mensaje: " "}) }
                    
                    />
                
            </Grid>
                    
            <Grid item xs={12} md={3}>
                <InputMask
                    mask="999-999-9999"
                    value={telefono.valor}
                    onChange={ (event) => set_telefono({ valor: event.target.value, error: false, mensaje: " "}) }
                    >
                    { (props) =>       
                        <TextField
                            {...props}
                            error={telefono.error}
                            helperText={telefono.mensaje}
                            margin="normal"
                            required
                            fullWidth
                            label="Teléfono"
                            type="text"
                        
                        /> 
                    }
                </InputMask> 
            </Grid>
                
            </Grid>

            <Grid container spacing={2}>

            <Grid item xs={12} md={4}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    select
                    label="Nacionalidad"
                    value={nacionalidad_id.valor}
                    onChange={(event) => set_nacionalidad_id({ valor: event.target.value, error: false, mensaje: " "})}
                    error={nacionalidad_id.error}
                    helperText={nacionalidad_id.mensaje}
                    >
                    {
                        nacionalidades.map( (nacionalidad) => {
                            return(
                                <MenuItem key={nacionalidad.id} value={nacionalidad.id}>{nacionalidad.nombre}</MenuItem>
                            );
                        })
                        
                    }
                </TextField>   
            </Grid>

            <Grid item xs={12} md={4}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    select
                    label="Lugar de Nacimiento"
                    value={lugar_de_nacimiento_id.valor}
                    onChange={(event) => set_lugar_de_nacimiento_id({ valor: event.target.value, error: false, mensaje: " "})}
                    error={lugar_de_nacimiento_id.error}
                    helperText={lugar_de_nacimiento_id.mensaje}
                    >
                    {
                        lugares_de_naciomiento.map( (lugar_de_nacimiento) => {
                            return(
                                <MenuItem key={lugar_de_nacimiento.id} value={lugar_de_nacimiento.id}>{lugar_de_nacimiento.nombre}</MenuItem>
                            );
                        })
                        
                    }
                </TextField>   
            </Grid>

            <Grid item xs={12} md={4}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    select
                    label="Sexo"
                    value={sexo_id.valor}
                    onChange={(event) => set_sexo_id({ valor: event.target.value, error: false, mensaje: " "})}
                    error={sexo_id.error}
                    helperText={sexo_id.mensaje}
                    >
                    {
                        sexos.map( (sexo) => {
                            return(
                                <MenuItem key={sexo.id} value={sexo.id}>{sexo.nombre}</MenuItem>
                            );
                        })
                        
                    }
                </TextField>      
            </Grid>

            </Grid>


            <Typography variant="subtitle2" gutterBottom component="div" mt={1}>
                    Dirección
            </Typography>
            <Divider />

            <Grid container spacing={2}>

            <Grid item xs={12} md={4}>

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    select
                    label="Provincia"
                    value={provincia_id.valor}
                    onChange={(event) => {

                        set_provincia_id({ valor: event.target.value, error: false, mensaje: " "});
                        set_municipio_id({ valor: "", error: false, mensaje: " "});
                    }}
                    error={provincia_id.error}
                    helperText={provincia_id.mensaje}
                    >
                    {
                        provincias.map( (provincia) => {
                            return(
                                <MenuItem key={provincia.id} value={provincia.id}>{provincia.nombre}</MenuItem>
                            );
                        })
                        
                    }
                </TextField>             
                
            </Grid>

            <Grid item xs={12} md={4}>

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    select
                    label="Municipio"
                    value={municipio_id.valor}
                    onChange={(event) => set_municipio_id({ valor: event.target.value, error: false, mensaje: " "})}
                    error={municipio_id.error}
                    helperText={municipio_id.mensaje}
                    >
                    {
                        municipios.map( (municipio) => {
                            return(
                                <MenuItem key={municipio.id} value={municipio.id}>{municipio.nombre}</MenuItem>
                            );
                        })
                        
                    }
                </TextField>
            
            </Grid>
                    
            <Grid item xs={12} md={4}>

                <TextField
                    error={direccion.error}
                    helperText={direccion.mensaje}
                    margin="normal"
                    required
                    fullWidth
                    name="direccion"
                    label="Dirección"
                    type="text"
                    id="direccion"
                    value={direccion.valor}
                    onChange={ (event) => set_direccion({ valor: event.target.value, error: false, mensaje: " "}) }
                    
                    />
                
            </Grid>
                
            </Grid>


        <Toolbar>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box>
        <Button
              type="submit" 
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
            REGISTRAR
        </Button>
        <Box sx={{ width: '100%', visibility: (procesando ? 'visible' : 'hidden') }}>
            <LinearProgress />
        </Box>
        </Box>
        
        </Toolbar>

        <Snackbar
            open={notificacion.mensaje != ""}
            autoHideDuration={6000}
            onClose={cerrar_notificacion}
        
        
        >
            <Alert onClose={cerrar_notificacion} severity={notificacion.tipo} sx={{ width: '100%' }}>
            {notificacion.mensaje}
            </Alert>
        </Snackbar>
        </Box>
    );
}

export default FormAfiliado;